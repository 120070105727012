import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const publicDebatesInfoService = {
  getAll,
  search,
  deleteInfoDocuments
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/public_debates/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function search(searchInfoDocument, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/public_debates/search?title=${searchInfoDocument.title}&archiveYear=${
    searchInfoDocument.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteInfoDocuments(infoDocumentId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/public_debates_informations/${infoDocumentId}`;

  return axios.delete(url, requestOptionsDelete);
}
