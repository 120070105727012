import axios from 'axios';

export const dataDeletionService = {
  deletePersonalData,
  verifyToken
};

function deletePersonalData(userEmail) {
  const headersOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  const url = `${process.env.REACT_APP_API_URL}/api/data_deletion/user_data_deletion`;

  return axios.post(
    url,
    { data_deletion: { email: userEmail } },
    headersOptions
  );
}

function verifyToken(token) {
  let url = `${process.env.REACT_APP_API_URL}/api/data_deletion/verify_token`;

  const requestOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  return axios.post(url, token, requestOptions);
}
