import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const councilMeetingService = {
  getAll,
  search,
  createOrUpdate,
  deleteCouncilMeeting
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/council_meetings/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function search(searchCouncilMeeting, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/council_meetings/search?title=${
    searchCouncilMeeting.title
  }&archiveYear=${searchCouncilMeeting.archiveYear}&page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function createOrUpdate(newCouncilMeeting) {
  const createRequestOptions = {
    method: newCouncilMeeting.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: newCouncilMeeting
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    createRequestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/council_meetings${
    newCouncilMeeting.id ? `/${newCouncilMeeting.id}` : ''
  }`;

  return fetch(url, createRequestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteCouncilMeeting(councilMeetingId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/council_meetings/${councilMeetingId}`;

  return axios.delete(url, requestOptionsDelete);
}
