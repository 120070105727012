import React from 'react';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { programsService } from '../../../services/ProgramsService';

class Programs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledDocument: false,
      newProgram: {
        title: '',
        document: '',
        add_date: ''
      },
      existingPrograms: [],
      deleteProgram: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };
  }

  componentWillMount() {
    programsService
      .getAll()
      .then(response => {
        this.setProgramsState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    programsService
      .getAll(selectedPage, selectedPage + 1)
      .then(jsonResponse => {
        this.setProgramsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setProgramsState = response => {
    let existingPrograms = { ...this.state.existingPrograms };
    let pagination = { ...this.state.pagination };
    existingPrograms = response.programs;
    pagination = response.pagination;

    this.setState({ existingPrograms });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    let newProgram = { ...this.state.newProgram };
    newProgram[field] = event.target.value;
    this.setState({
      newProgram
    });
  };

  resetForm = () => {
    let newProgram = { ...this.state.newProgram };
    newProgram = {
      title: '',
      document: ''
    };
    this.setState({ newProgram });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();

    programsService
      .createOrUpdate(this.state.newProgram)
      .then(response => {
        this.resetForm();

        let existingPrograms = this.state.existingPrograms;

        let index = existingPrograms.findIndex(
          existingProgram => existingProgram.id === response.id
        );

        if (index !== -1) {
          existingPrograms[index] = response;
        } else {
          existingPrograms.push(response);
        }

        this.setState({
          existingPrograms
        });

        this._notificationSystem.addNotification({
          message: `Programul a fost ${
            this.state.newProgram.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  onDropDocument = documents => {
    let newProgram = { ...this.state.newProgram };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newProgram.document = documents[0];

      self.setState({ newProgram });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newProgram = { ...this.state.newProgram };
    newProgram.document = null;

    this.setState({ newProgram });
    this.setState({ disabledDocument: false });
  };

  openDeleteDialog = id => {
    let deleteProgram = this.state.deleteProgram;
    deleteProgram = {
      openedDialog: true,
      deleteId: id
    };
    this.setState({
      deleteProgram
    });
  };

  closeDeleteDialog = id => {
    let deleteProgram = this.state.deleteProgram;
    deleteProgram = {
      openedDialog: false,
      deleteId: 0
    };
    this.setState({
      deleteProgram
    });
  };

  setProgramForEdit = program => {
    let newProgram = { ...this.state.newProgram };
    newProgram = Object.assign({}, program);
    this.setState({ newProgram });

    if (newProgram.document && newProgram.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    programsService
      .deleteProgram(this.state.deleteProgram.deleteId)
      .then(() => {
        let existingPrograms = this.state.existingPrograms.slice();
        existingPrograms = existingPrograms.filter(
          program => program.id !== this.state.deleteProgram.deleteId
        );
        this.setState({ existingPrograms });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Programul a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Programe si strategii
          </h3>
          <h1 className="admin-switch-title default-title">Programe</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga un program</h2>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu program*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newProgram.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Data program*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.newProgram.add_date}
                  />
                </div>

                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document program:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newProgram.document}
                    documentFilename={this.state.newProgram.document_filename}
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newProgram.id
                        ? 'Editează Program'
                        : 'Adaugă Program'}
                    </button>
                  </div>
                  {Object.values(this.state.newProgram) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingPrograms.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Programe si strategii existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingPrograms.map(program => (
                      <div
                        className="existing-items__content-card"
                        key={program.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {program.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setProgramForEdit(program)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(program.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <DeletePUGCertificates
                    opened={this.state.deleteProgram.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest program?"
                    aditionalText="Dupa stergere, programul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default Programs;
