import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { councilMeetingService } from '../../services/CouncilMeetingsService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';
import ReactPlayer from 'react-player/lazy';

class CouncilMeetings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      councilMeetings: [],
      searchConvocationAgenda: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    councilMeetingService
      .getAll()
      .then(jsonResponse => {
        this.setConvocationAgendaState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setConvocationAgendaState = response => {
    let councilMeetings = { ...this.state.councilMeetings };
    let pagination = { ...this.state.pagination };
    councilMeetings = response.council_meetings;
    pagination = response.pagination;

    this.setState({ councilMeetings });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchConvocationAgenda = this.state.searchConvocationAgenda;
    searchConvocationAgenda[field] = event.target.value;

    this.setState(
      {
        searchConvocationAgenda
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    councilMeetingService
      .search(this.state.searchConvocationAgenda, pageNumber)
      .then(jsonResponse => {
        this.setConvocationAgendaState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Sedinte consiliu</title>
          <meta
            name="description"
            content="Sedinte consiliu de zi Primaria Sucevita. Aici sunt prezentate detaliile cu privire la sedintele consiliului."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Sedinte Consiliu" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Sedinte Consiliu</h4>
          </div>
          <h2 className="standard__main-title">Sedinte Consiliu</h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__archive-box">
            <FormControl>
              <NativeSelect
                value={this.state.searchConvocationAgenda.archiveYear}
                onChange={this.updateField}
                inputProps={{
                  name: 'archiveYear'
                }}
              >
                <option value="">Arhiva</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
              </NativeSelect>
            </FormControl>
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.councilMeetings.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.councilMeetings.length > 0 ? (
              this.state.councilMeetings.map(councilMeeting => (
                <div
                  className="standard__individual-card standard__individual-card--big"
                  key={councilMeeting.id}
                >
                  <h3 className="standard__card-title">
                    {councilMeeting.title}
                  </h3>
                  <h4 className="standard__card-date">
                    {councilMeeting.add_date}
                  </h4>
                  <div className="standard__video-wrapper">
                    <ReactPlayer
                      url={councilMeeting.url}
                      className="header__video"
                      width="100%"
                      height="100%"
                      playing={false}
                      loop={true}
                      controls={false}
                      volume={1}
                      muted={false}
                      playsinline={false}
                      controls={true}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Nu sunt rezultate pentru căutare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default CouncilMeetings;
