import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import '../SubmittedProjects/SubmittedProjects';
import MetaTags from 'react-meta-tags';
import { externalProjectsService } from '../../services/ExternalProjectsService';
import ReactPaginate from 'react-paginate';
import '../StandardPage/StandardPage.css';

class ExternalProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      externalProjects: [],
      pagination: {}
    };
  }

  componentWillMount() {
    externalProjectsService
      .getAll()
      .then(jsonResponse => {
        this.setExternalProjectsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setExternalProjectsState = response => {
    let externalProjects = { ...this.state.externalProjects };
    let pagination = { ...this.state.pagination };
    externalProjects = response.external_projects;
    pagination = response.pagination;

    this.setState({ externalProjects });
    this.setState({ pagination });
  };

  handlePageClick = selectedPage => {
    externalProjectsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setExternalProjectsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Proiecte Cu Finantare Externa</title>
          <meta
            name="description"
            content="Proiecte Cu Finantare Externa Primaria Sucevita. Aici sunt prezentate proiecte cu finantare externa ce vor fi realizate in Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Proiecte Cu Finantare Externa" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Proiecte > </h4>
            <h4 className="standard__generic">Proiecte Cu Finanțare Externă</h4>
          </div>
          <h2 className="standard__main-title">
            Proiecte cu Finanțare Externă
          </h2>
        </div>

        <div className="standard__projects">
          {this.state.externalProjects.length > 0 ? (
            <div
              className={
                'standard__projects__wrapper ' +
                (this.state.externalProjects.length <= 2
                  ? 'standard__projects-wrapper-few'
                  : '')
              }
            >
              {this.state.externalProjects.map(project => (
                <div className="standard__individual-project" key={project.id}>
                  <div className="standard__project-image-info ">
                    {project.picture && project.picture.url !== null ? (
                      <img
                        className="standard__project-image"
                        src={`${process.env.REACT_APP_API_URL}${project.picture.url}`}
                        alt="Project"
                      />
                    ) : (
                      <img
                        src="/images/generalPresentation/project.jpeg"
                        className="standard__project-image"
                        alt="project"
                      />
                    )}
                    <div className="standard__project-info ">
                      <div className="standard__project-value-box">
                        <h4 className="standard__project-value">
                          {project.value}
                        </h4>
                      </div>
                      <div className="standard__project-date-box">
                        <h5 className="standard__project-date">
                          {project.start_date}-{project.end_date}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <h3 className="standard__project-title">{project.title}</h3>
                  <h4 className="standard__project-main-description">
                    {project.description}
                  </h4>
                  <h4 className="standard__see-project">
                    Vezi Detalii Proiect
                  </h4>

                  {project.document && project.document.url !== null && (
                    <div className="submitted-projects__project-button">
                      <button
                        className="standard__button standard__project-button"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${project.document.url}`
                          )
                        }
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        <h4 className="standard__button-writing">
                          {' '}
                          Vezi Document Proiect
                        </h4>
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="standard__no-projects-box">
              <p className="standard__no-projects">
                Nu sunt proiecte adăugate momentan.
              </p>
            </div>
          )}
        </div>
        {this.state.pagination.last_page > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pagination.last_page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    );
  }
}

export default ExternalProjects;
