import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import MetaTags from 'react-meta-tags';

class EducationalStimulents extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Stimulente Educationale si de Insertie</title>
          <meta
            name="description"
            content="Stimulente Educationale si de Insertie Primaria Sucevita. Aici sunt prezentate conditiile necesare si formularele pentru stimulentele educationale si de insertie."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta
            property="og:title"
            content="Stimulente Educationale si de Insertie"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Asistență Socială </h4>
          </div>
          <h2 className="standard__main-title">
            Stimulente Educaționale și de Inserție
          </h2>
        </div>

        <div className="standard__wrapper standard__wrapper-no-search standard__wrapper-ed-stimulents">
          <div className="standard__individual-ed-stimulents">
            <h3
              className="standard__submitted-title"
              id="educational-stimulents-title"
            >
              Condiții necesare
            </h3>
            <div className="standard__cards standard__projects-wrapper-few standard__educational-stimulents">
              <DownloadCard
                requiredWidth="required-width-ed-stimulents"
                widthClass="standard__card-width"
                title="Conditii de acordare a stimulentului educational"
                url="/documents/stimulente-educationale-conditii-acordare.pdf"
              />
            </div>
          </div>
          <div className="standard__individual-ed-stimulents">
            <h3
              className="standard__submitted-title"
              id="educational-stimulents-title"
            >
              Acte necesare
            </h3>
            <div className="standard__cards standard__projects-wrapper-few standard__educational-stimulents">
              <DownloadCard
                requiredWidth="required-width-ed-stimulents"
                widthClass="standard__card-width"
                title="Conditii de acordare a stimulentului educational"
                url="/documents/acte_necesare_stimulent_insertie.pdf"
              />
            </div>
          </div>

          <div className="standard__individual-ed-stimulents">
            <h3
              className="standard__submitted-title"
              id="educational-stimulents-title"
            >
              Formulare necesare
            </h3>
            <div className="standard__cards standard__projects-wrapper-few standard__educational-stimulents">
              <DownloadCard
                requiredWidth="required-width-ed-stimulents"
                title="Cerere pentru acordarea stimulentului educational"
                url="/documents/Formular-tichete-gradinita.pdf"
              />
            </div>
          </div>
          <div className="standard__individual-ed-stimulents">
            <h3
              className="standard__submitted-title"
              id="educational-stimulents-title"
            >
              Stimulente de inserție
            </h3>
            <div className="standard__cards standard__projects-wrapper-few standard__educational-stimulents">
              <DownloadCard
                requiredWidth="required-width-ed-stimulents"
                title="Cerere stimulent de insertie"
                url="/documents/cerere-formular-indemnizatie-crestere-copil.pdf"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EducationalStimulents;
