import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { organizationRegulamentService } from '../../services/OrganizationRegulamentService';

class StructureRegulament extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      pagination: ''
    };
  }
  componentWillMount() {
    organizationRegulamentService
      .getAll()
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.setStructureRegulamentState(jsonResponse);
      })
      .catch(error => console.log(error));
  }
  setStructureRegulamentState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.organization_regulations;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Transparenta Venituri Salariale</title>
          <meta
            name="description"
            content="Transparenta Venituri Salariale Vicovu de Sus. Aici este prezentata transparenta veniturilor salariale din Vicovu de Sus."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Transparenta Venituri Salariale" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Regulamentul de organizare</h4>
          </div>
          <h2 className="standard__main-title">Regulamentul de Organizare</h2>
        </div>
        <div className="standard__wrapper wrapper-no-search">
          <ul className="monitor__list">
            <h3 className="monitor__subtitle">
              Din data de 01.06.2021, Monitorul Oficial Local al comunei
              Sucevita poate fi vizualizat{' '}
              <a
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                aici
              </a>
              .
            </h3>
          </ul>
          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default StructureRegulament;
