// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class Agriculture extends Component {
  render() {
    console.log('here');
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Agricultura
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Suprafața agricolă, pe categorii de folosință în anul 2014:
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">Categorii de folosinta</div>
                  <div className="text text--final">Suprafata (ha)</div>
                  <div className="text text--final">
                    Ponderea suprafetelor (ha)
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">- arabil</div>
                  <div className="text">2342</div>
                  <div className="text">73%</div>
                </div>
                <div className="table__row">
                  <div className="text">- pasuni si fanete</div>
                  <div className="text">475.94</div>
                  <div className="text">15%</div>
                </div>
                <div className="table__row">
                  <div className="text">- vii si livezi</div>
                  <div className="text">57</div>
                  <div className="text">2.5%</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">Total teren agricol</div>
                  <div className="text">3190</div>
                  <div className="text" />
                </div>
              </div>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Situația terenurilor, pe categorii de folosință, potrivit
                Recensământului agricol din anul 2010:
              </h4>
              <h5>Terenul arabil: 2342.87</h5>
              <h5>Gradina familiala: 14.94</h5>
              <h5>Pasuni si fanete: 475.94</h5>
              <h5>Culturi permanente: 18.6</h5>
              <h5>Suprafata agricola utilizata: 2852.35</h5>
              <h5>Suprafata agricola neutilizata: 190.56</h5>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Exploatații agricole (număr) după modul de detinere a suprafeței
                agricole utilizate:
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">
                    Modul de detinere a suprafetei
                  </div>
                  <div className="text text--final">Oras Sucevita</div>
                  <div className="text text--final">Judet Suceava</div>
                </div>
                <div className="table__row">
                  <div className="text">In proprietate</div>
                  <div className="text">3410</div>
                  <div className="text">155093</div>
                </div>
                <div className="table__row">
                  <div className="text">In concesiune</div>
                  <div className="text">4</div>
                  <div className="text">561</div>
                </div>
                <div className="table__row">
                  <div className="text">In arenda</div>
                  <div className="text">4</div>
                  <div className="text">6660</div>
                </div>
                <div className="table__row">
                  <div className="text">In parte</div>
                  <div className="text">2</div>
                  <div className="text">1980</div>
                </div>
                <div className="table__row">
                  <div className="text">Utilizat cu titlu gratuit</div>
                  <div className="text">4</div>
                  <div className="text">5583</div>
                </div>
                <div className="table__row">
                  <div className="text">Alte moduri</div>
                  <div className="text">-</div>
                  <div className="text">1482</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">Total</div>
                  <div className="text text--final">3424</div>
                  <div className="text text--final">171359</div>
                </div>
              </div>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Exploatații agricole (numar) care utilizează terenul arabil,
                după modul de deținere al acestuia:
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">
                    Modul de detinere al terenului arabil
                  </div>
                  <div className="text text--final">Oras Sucevita</div>
                  <div className="text text--final">Judet Suceava</div>
                </div>
                <div className="table__row">
                  <div className="text">In proprietate</div>
                  <div className="text">3354</div>
                  <div className="text">125879.43</div>
                </div>
                <div className="table__row">
                  <div className="text">In concesiune</div>
                  <div className="text">-</div>
                  <div className="text">771.24</div>
                </div>
                <div className="table__row">
                  <div className="text">In arenda</div>
                  <div className="text">3</div>
                  <div className="text">17111.39</div>
                </div>
                <div className="table__row">
                  <div className="text">In parte</div>
                  <div className="text">2</div>
                  <div className="text">2469.75</div>
                </div>
                <div className="table__row">
                  <div className="text">Utilizat cu titlu gratuit</div>
                  <div className="text">4</div>
                  <div className="text">3788.42</div>
                </div>
                <div className="table__row">
                  <div className="text">Alte moduri</div>
                  <div className="text">-</div>
                  <div className="text">740.05</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">Total</div>
                  <div className="text text--final">3363</div>
                  <div className="text text--final">150760,28</div>
                </div>
              </div>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Exploataţii agricole (număr), pe clase de mărime a terenului
                arabil
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">Clase de marime</div>
                  <div className="text text--final">Oras Sucevita</div>
                  <div className="text text--final">Judet Suceava</div>
                </div>
                <div className="table__row">
                  <div className="text">Sub 0.1 ha</div>
                  <div className="text">67</div>
                  <div className="text">2363</div>
                </div>
                <div className="table__row">
                  <div className="text">0.1 - 0.3 ha</div>
                  <div className="text">633</div>
                  <div className="text">20909</div>
                </div>
                <div className="table__row">
                  <div className="text">0.3 - 0.5 ha</div>
                  <div className="text">636</div>
                  <div className="text">18186</div>
                </div>
                <div className="table__row">
                  <div className="text">0.5 - 1 ha</div>
                  <div className="text">1060</div>
                  <div className="text">34395</div>
                </div>
                <div className="table__row">
                  <div className="text">1 -2 ha</div>
                  <div className="text">795</div>
                  <div className="text">40436</div>
                </div>
                <div className="table__row">
                  <div className="text">2 - 5 ha</div>
                  <div className="text">212</div>
                  <div className="text">32741</div>
                </div>
                <div className="table__row">
                  <div className="text">5 - 10 ha</div>
                  <div className="text">7</div>
                  <div className="text">5981</div>
                </div>
                <div className="table__row">
                  <div className="text">10 - 20 ha</div>
                  <div className="text">1</div>
                  <div className="text">1730</div>
                </div>
                <div className="table__row">
                  <div className="text">20 - 30 ha</div>
                  <div className="text">-</div>
                  <div className="text">356</div>
                </div>
                <div className="table__row">
                  <div className="text">30 - 50 ha</div>
                  <div className="text">-</div>
                  <div className="text">221</div>
                </div>
                <div className="table__row">
                  <div className="text">50 - 100 ha</div>
                  <div className="text">-</div>
                  <div className="text">126</div>
                </div>
                <div className="table__row">
                  <div className="text">peste 100 ha</div>
                  <div className="text">-</div>
                  <div className="text">137</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">Total</div>
                  <div className="text text--final">3411</div>
                  <div className="text text--final">1575841</div>
                </div>
              </div>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Cultura plantelor
              </h3>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">Culturi</div>
                  <div className="text text--final">
                    <div>Oras Sucevita</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">Suprafata Cultivata (ha)</div>
                    </div>
                  </div>
                  <div className="text text--final">
                    <div>Judet Suceava</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">Suprafata Cultivata (ha)</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Grâu comun si grâu spelt</div>
                  <div className="text">15</div>
                  <div className="text">17421,45</div>
                </div>
                <div className="table__row">
                  <div className="text">Porumb</div>
                  <div className="text">250</div>
                  <div className="text">38269</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Plante tehnice (floarea soarelui, rapiță si soia)
                  </div>
                  <div className="text">-</div>
                  <div className="text">2141</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Legume, pepeni (in camp si in sere)
                  </div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Plante de nutreţ (fân şi masă verde, leguminoase pentru
                    nutreţ- lucernă, trifoi)
                  </div>
                  <div className="text">1685</div>
                  <div className="text">49428</div>
                </div>
                <div className="table__row">
                  <div className="text">Cartofi</div>
                  <div className="text">340</div>
                  <div className="text">18145</div>
                </div>
                <div className="table__row">
                  <div className="text">Alte culture</div>
                  <div className="text">125</div>
                  <div className="text">106</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">
                    TOTAL ARABIL (din suprafata utilizata)
                  </div>
                  <div className="text text--final">2415</div>
                  <div className="text text--final">125512</div>
                </div>
              </div>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Suprafetele cultivate in Sucevita
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">Cultura</div>
                  <div className="text text--final">
                    Suprafata cultivata (ha)
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Meri</div>
                  <div className="text">9654 buc</div>
                </div>
                <div className="table__row">
                  <div className="text">Pruni</div>
                  <div className="text">7640 buc</div>
                </div>
                <div className="table__row">
                  <div className="text">Alti pomi fructiferi</div>
                  <div className="text">7060 buc</div>
                </div>
                <div className="table__row">
                  <div className="text">Struguri de masa</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">Struguri de vin</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">Total</div>
                  <div className="text text--final">40 hectare</div>
                </div>
              </div>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Situația producțiilor medii la principalele culturi in județul
                Suceava
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">Denumire Cultura</div>
                  <div className="text text--final">U.M.</div>
                  <div className="text text--final">Productia 2011</div>
                  <div className="text text--final">Productia 2014</div>
                </div>
                <div className="table__row">
                  <div className="text">Grau</div>
                  <div className="text">Kg/ha</div>
                  <div className="text">2742</div>
                  <div className="text">3257</div>
                </div>
                <div className="table__row">
                  <div className="text">Porumb</div>
                  <div className="text">ka/ha</div>
                  <div className="text">3895</div>
                  <div className="text">4113</div>
                </div>
                <div className="table__row">
                  <div className="text">Pepeni verzi si galbeni</div>
                  <div className="text">kg/ha</div>
                  <div className="text">0</div>
                  <div className="text">0</div>
                </div>
                <div className="table__row">
                  <div className="text">Cartofi</div>
                  <div className="text">tone</div>
                  <div className="text">15173</div>
                  <div className="text">18983</div>
                </div>
              </div>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Cresterea animalelor
              </h3>
              <h4
                className="standard__generic aa-description"
                id="gp-description"
              >
                Efectivele de animale din localitatea Sucevita, conform
                Recensământului agricol 2010:
              </h4>
              <div className="table">
                <div className="table__row table__row--header table__row--green">
                  <div className="text text--final">
                    Efective de animale (capete)
                  </div>
                  <div className="text text--final">Judet Suceava</div>
                  <div className="text text--final">Sucevita</div>
                </div>
                <div className="table__row">
                  <div className="text">Bovine</div>
                  <div className="text">147753</div>
                  <div className="text">1925</div>
                </div>
                <div className="table__row">
                  <div className="text">Ovine</div>
                  <div className="text">225475</div>
                  <div className="text">3450</div>
                </div>
                <div className="table__row">
                  <div className="text">Caprine</div>
                  <div className="text">15385</div>
                  <div className="text">930</div>
                </div>
                <div className="table__row">
                  <div className="text">Porcine</div>
                  <div className="text">84760</div>
                  <div className="text">1380</div>
                </div>
                <div className="table__row">
                  <div className="text">Păsări</div>
                  <div className="text">1417113</div>
                  <div className="text">7754</div>
                </div>
                <div className="table__row">
                  <div className="text">Cabaline</div>
                  <div className="text">26959</div>
                  <div className="text">487</div>
                </div>
                <div className="table__row">
                  <div className="text">Familii de albine</div>
                  <div className="text">22886</div>
                  <div className="text">90</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">Total</div>
                  <div className="text text--final">1940331</div>
                  <div className="text text--final">16016</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Agriculture;
