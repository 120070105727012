import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { electionsService } from '../../services/ElectionsService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

class Elections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elections: [],
      searchElection: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: [
        {
          id: 1,
          name: 'Arhiva',
          value: '2024'
        },
        {
          id: 1,
          name: '2020',
          value: '2020'
        }
      ],
      pagination: {}
    };
  }

  componentDidMount() {
    electionsService
      .getAll()
      .then(jsonResponse => {
        this.setElectionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    if (this.state.searchElection.title) {
      this.search(selectedPage.selected + 1);
    } else {
      electionsService
        .getAll(selectedPage.selected + 1)
        .then(jsonResponse => {
          this.setElectionsState(jsonResponse);
        })
        .catch(error => console.log(error));
    }
  };

  setElectionsState = response => {
    let elections = this.state.elections;
    let pagination = { ...this.state.pagination };
    elections = response.data.elections;
    pagination = response.data.pagination;

    this.setState({ elections });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchElection = this.state.searchElection;
    searchElection[field] = event.target.value;

    this.setState(
      {
        searchElection
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    electionsService
      .search(this.state.searchElection, pageNumber)
      .then(jsonResponse => {
        this.setElectionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Alegeri 2024</title>
          <meta
            name="description"
            content="Proiecte de hotarari Primaria Vicovu de Sus. Aici sunt prezentate proiectele de hotarari ale primariei."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, Proiecte Finalizate, autorizatii de construire, alegeri 2024"
          />
          <meta property="og:title" content="Proiecte de hotarari" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Alegeri 2024</h4>
          </div>
          <h2 className="standard__main-title">Documente Alegeri 2024</h2>
        </div>

        <div className="standard__wrapper ">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchElection.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchElection.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.elections.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.elections.length > 0 ? (
              this.state.elections.map(election => (
                <div key={election.id} className="standard__individual-card">
                  <h3 className="standard__card-title">{election.title}</h3>
                  <h4 className="standard__card-date">{election.add_date}</h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${election.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${election.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Nu sunt rezultate pentru căutare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Elections;
