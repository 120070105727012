import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class MemorandumContracts extends Component {
  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Contracte incepand cu data aplicarii memorandumului</title>
          <meta
            name="description"
            content="Contracte incepand cu data aplicarii memorandumului."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Contracte Achizitii" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Achizitii</h4>
          </div>
          <h2 className="standard__main-title">
            Contracte începând cu data aplicării memorandului
          </h2>
        </div>
        {/*<div className="standard__wrapper wrapper-no-search">
          <div className="standard__cards standard__projects-wrapper-few">
            <div className="standard__individual-card">
              <h3 className="standard__card-title">
                Transparenta Venituri Salariale Septembrie 2018
              </h3>
              <h4
                className="standard__see-project"
                onClick={() =>
                  window.open(
                    ''
                  )
                }
              >
                Vizualizează
              </h4>
              <img
                src="/images/icons/file.svg"
                className="standard__card-img standard__card-img-local"
                alt="file"
                onClick={() =>
                  window.open(
                    ''
                  )
                }
              />
            </div>
          </div>
              </div>*/}
        <div className="standard__no-projects-box">
          <p className="standard__no-projects">
            Informații in curs de actualizare.
          </p>
        </div>
      </div>
    );
  }
}

export default MemorandumContracts;
