// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class DemographyArea extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Demografie
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Reperele demografice ale evolutiei la nivelul Primăriei Vicovu
                de Sus reprezintă elemente de interes major pentru definirea
                obiectivelor strategice si a planurilor de actiune pe termen
                mediu si lung.{' '}
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">Localitatea</div>
                  <div className="text">
                    <div>Populatie stabilă (numar persoane)</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">RPL 2011</div>
                    </div>
                  </div>
                  <div className="text">
                    Reactualizare RPL 2014 prin serviciul de evident a
                    populatiei
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">JUDET Suceava</div>
                  <div className="text">634810</div>
                  <div className="text">631866</div>
                </div>
                <div className="table__row table__row--green">
                  <div className="text">ORAS Sucevita</div>
                  <div className="text">14970</div>
                  <div className="text">15929</div>
                </div>
              </div>
              <h4 className="standard__generic" id="gp-description">
                Populatia stabilă din mediul urban al judetului Suceava
              </h4>
              <h4
                className="standard__generic  dd-description"
                id="gp-description"
              >
                <b>Densitatea populației</b> în orașul Sucevita este de 353
                locuitori/kmp, situându-se peste pe județ (82.6 locuitori/kmp).
              </h4>
              <h4
                className="standard__generic  dd-description"
                id="gp-description"
              >
                <b>După etnie</b>, locuitorii din Orasul Sucevita sunt în
                proporție de peste 96% români si doar 4% reprezintă populația de
                etnie romă sau alte etnii. (de menționat care sunt acestea). La
                recensământul din octombrie 2011, înregistrarea etniei, limbii
                materne şi a religiei s-a făcut pe baza liberei declaraţii a
                persoanelor recenzate. Pentru persoanele care nu au vrut să
                declare aceste trei caracteristici, precum şi pentru persoanele
                pentru care informaţiile au fost colectate indirect din surse
                administrative, informaţia nu este disponibilă pentru aceste
                caracteristici.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">An</div>
                  <div className="text">Oraș Sucevita (număr persoane)</div>
                  <div className="text">JUD. Suceava (număr persoane)</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2010</div>
                  <div className="text">1017</div>
                  <div className="text">89411</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2011</div>
                  <div className="text">986</div>
                  <div className="text">87234</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2012</div>
                  <div className="text">1006</div>
                  <div className="text">88767</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2013</div>
                  <div className="text">982</div>
                  <div className="text">88623</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2014</div>
                  <div className="text" />
                  <div className="text">88678</div>
                </div>
              </div>
              <h4
                className="standard__generic dd-description"
                id="gp-description"
              >
                Numărul mediu al salariaților
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">An</div>
                  <div className="text">
                    Şomerii înregistraţi la Agenţiile pentru ocuparea forţei de
                    muncă (număr persoane)
                  </div>
                  <div className="text">din care: Femei (număr persoane)</div>
                  <div className="text">Rata şomajului- total (%)</div>
                  <div className="text">Rata şomajului- femei (%)</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2010</div>
                  <div className="text">18856</div>
                  <div className="text">7752</div>
                  <div className="text">7.3</div>
                  <div className="text">6.6</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2011</div>
                  <div className="text">11908</div>
                  <div className="text">5442</div>
                  <div className="text">4.9</div>
                  <div className="text">4.5</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2012</div>
                  <div className="text">13881</div>
                  <div className="text">5789</div>
                  <div className="text">5.5</div>
                  <div className="text">4.7</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2013</div>
                  <div className="text">16185</div>
                  <div className="text">6748</div>
                  <div className="text">6.5</div>
                  <div className="text">5.8</div>
                </div>
              </div>
              <h4 className="standard__generic" id="gp-description">
                Evolutia numarului Şomerilor înregistraţi si Rata şomajului
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Impărțirea populației pe grupe de vârstă
              </h3>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">Grupa de vârstă</div>
                  <div className="text">Nr. persoane</div>
                  <div className="text">Procent %</div>
                </div>
                <div className="table__row">
                  <div className="text">sub 5 ani</div>
                  <div className="text">1357</div>
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">5 - 9 ani</div>
                  <div className="text">1313</div>
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">10 - 14 ani</div>
                  <div className="text">1335</div>
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">15 - 19 ani</div>
                  <div className="text">1309</div>
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">20 - 24 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">25 - 34 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">35 - 49 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">50 - 59 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">60 - 64 ani</div>
                  <div className="text">599</div>
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">65 - 74 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">75 - 84 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">peste 85 ani</div>
                  <div className="text" />
                  <div className="text" />
                </div>
                <div className="table__row">
                  <div className="text">TOTAL POPULATIE</div>
                  <div className="text" />
                  <div className="text" />
                </div>
              </div>
              <h4 className="standard__generic" id="gp-description">
                Populația din orașul Sucevita pe grupe de vârstă
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">An</div>
                  <div className="text">
                    <div>Născuți vii</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">Suceava</div>
                      <div className="text">Sucevita</div>
                    </div>
                  </div>
                  <div className="text">
                    <div>Decedați</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">Suceava</div>
                      <div className="text">Sucevita</div>
                    </div>
                  </div>
                  <div className="text">
                    <div>Sporul natural</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">Suceava</div>
                      <div className="text">Sucevita</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2010</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">8001</div>
                      <div className="text">221</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7579</div>
                      <div className="text">105</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">422</div>
                      <div className="text">116</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2011</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7876</div>
                      <div className="text">253</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7493</div>
                      <div className="text">140</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">383</div>
                      <div className="text">113</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2012</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">8189</div>
                      <div className="text">262</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7493</div>
                      <div className="text">140</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">383</div>
                      <div className="text">113</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2013</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">8303</div>
                      <div className="text">250</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7493</div>
                      <div className="text">123</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">810</div>
                      <div className="text">127</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2014</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7273</div>
                      <div className="text">206</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7671</div>
                      <div className="text">135</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">-398</div>
                      <div className="text">71</div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="standard__generic" id="gp-description">
                Mișcarea naturală a populației (număr persoane)
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemographyArea;
