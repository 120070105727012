import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { transparentEarningService } from '../../../services/TransparencyEarningsService';

class AdminTransparencyEarnings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newDocument: {
        title: '',
        document: '',
        add_date: ''
      },
      existingTransparentEarnings: [],
      deleteTransparentEarning: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }
  componentWillMount() {
    transparentEarningService
      .getAll()
      .then(response => {
        this.setTransparentEarningsDocumentState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setTransparentEarningsDocumentState = response => {
    let existingTransparentEarnings = this.state.existingTransparentEarnings;
    let pagination = { ...this.state.pagination };
    this.setState({
      existingTransparentEarnings
    });
    this.setState({
      pagination
    });
  };

  handlePageClick = selectedPage => {
    transparentEarningService
      .getAll(selectedPage, selectedPage + 1)
      .then(jsonResponse => {
        this.setTransparentEarningsDocumentState(jsonResponse);
      })
      .catch(error => {
        console.log(error);
      });
  };

  updateField = event => {
    const field = event.target.name;
    let newDocument = { ...this.state.newDocument };
    newDocument[field] = event.target.value;
    this.setState({
      newDocument
    });
  };

  resetForm = () => {
    let newDocument = { ...this.state.newDocument };
    newDocument = {
      title: '',
      document: ''
    };
    this.setState({ newDocument });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    transparentEarningService
      .createOrUpdate(this.state.newDocument)
      .then(response => {
        this.resetForm();

        let existingTransparentEarnings = this.state
          .existingTransparentEarnings;

        let index = existingTransparentEarnings.findIndex(
          existingPublicInterestDocument =>
            existingPublicInterestDocument.id === response.id
        );

        if (index !== -1) {
          existingTransparentEarnings[index] = response;
        } else {
          existingTransparentEarnings.push(response);
        }

        this.setState({
          existingTransparentEarnings
        });

        this._notificationSystem.addNotification({
          message: `Documentul a fost ${
            this.state.publicInterestDocument.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  onDropDocument = documents => {
    let newDocument = { ...this.state.newDocument };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newDocument.document = documents[0];

      self.setState({ newDocument });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newDocument = { ...this.state.newDocument };
    newDocument.document = null;

    this.setState({ newDocument });
    this.setState({ disabledDocument: false });
  };

  openDeleteDialog = id => {
    let deleteTransparentEarning = { ...this.state.deleteTransparentEarning };
    deleteTransparentEarning = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteTransparentEarning });
  };

  closeDeleteDialog = () => {
    let deleteTransparentEarning = { ...this.state.deleteTransparentEarning };
    deleteTransparentEarning = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteTransparentEarning });
  };

  setTransparentEarningForEdit = transparentEarning => {
    let newDocument = { ...this.state.newDocument };
    newDocument = Object.assign({}, transparentEarning);
    this.setState({ newDocument });

    if (newDocument.document && newDocument.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    transparentEarningService
      .deletePIDocument(this.state.deleteTransparentEarning.deleteId)
      .then(() => {
        let existingTransparentEarnings = this.state.existingTransparentEarnings.slice();
        existingTransparentEarnings = existingTransparentEarnings.filter(
          piDocument =>
            piDocument.id !== this.state.deleteTransparentEarning.deleteId
        );
        this.setState({ existingTransparentEarnings });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Documentul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Transparența veniturilor salariale
          </h3>
          <h1 className="admin-switch-title default-title">
            Transparența veniturilor
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adaugă un document de transparență a veniturilor
                </h2>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu document*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newDocument.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată document*
                  </h4>
                  <input
                    className="general-field date-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.newDocument.add_date}
                  />
                </div>

                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newDocument.document}
                    documentFilename={this.state.newDocument.document_filename}
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newDocument.id
                        ? 'Editează Document'
                        : 'Adaugă Document'}
                    </button>
                  </div>
                  {Object.values(this.state.newDocument) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingTransparentEarnings &&
            this.state.existingTransparentEarnings.length > 0 && (
              <div className="admin-standard-page__existing-items">
                <div className="card">
                  <div className="existing-items">
                    <h2 className="existing-items__title label-title">
                      Documente existente
                    </h2>
                    <div className="existing-items__content">
                      {this.state.existingTransparentEarnings.map(document => (
                        <div
                          className="existing-items__content-card"
                          key={document.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {document.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() => this.setStrategyForEdit(document)}
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() => this.openDeleteDialog(document.id)}
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <DeletePUGCertificates
                      opened={this.state.deleteTransparentEarning.openedDialog}
                      handleClose={this.closeDeleteDialog}
                      handleDelete={this.handleDelete}
                      headline="Sunteti sigur ca doriti sa stergeti acest document?"
                      aditionalText="Dupa stergere, acest document nu va mai putea fi recuperata."
                    />
                  </div>
                  {this.state.pagination.last_page > 1 && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={this.state.pagination.last_page}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
              </div>
            )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}
export default AdminTransparencyEarnings;
