import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import './SolicitationModal.css';
import '../../resources/StandardPage.css';
import Modal from 'react-modal';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import DeletePUGCertificates from '../Dialogs/DeleteDialog/DeleteDialog';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(13, 8, 32, 0.9)',
    overflow: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 'auto'
  }
};

class SolicitationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedDialog: false
    };
  }

  openDeleteDialog = id => {
    this.setState({ openedDialog: true });
  };

  closeDeleteDialog = () => {
    this.setState({ openedDialog: false });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        ariaHideApp={false}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Event Modal"
      >
        <div className="solicitation-modal">
          <h2 className="standard__main-title solicitation-modal__title">
            CEREREA NR. {this.props.eventObject.id}
          </h2>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info">
              Nume solicitant:
            </h3>
            <h3 className="solicitation-modal__specific-info">
              {this.props.eventObject.name || '-'}
            </h3>
          </div>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info">
              Adresa solicitant:
            </h3>
            <h3 className="solicitation-modal__specific-info">
              {this.props.eventObject.address || '-'}
            </h3>
          </div>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info">Email:</h3>
            <h3 className="solicitation-modal__specific-info">
              {this.props.eventObject.email || '-'}
            </h3>
          </div>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info">
              Numar de telefon:
            </h3>
            <h3 className="solicitation-modal__specific-info">
              {this.props.eventObject.phone || '-'}
            </h3>
          </div>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info">
              Informatii solicitate:
            </h3>
            <h3 className="solicitation-modal__specific-info solicitation-modal__message">
              {this.props.eventObject.requested_information || '-'}
            </h3>
          </div>
          <div className="solicitation-modal__info-box">
            <h3 className="solicitation-modal__general-info solicitation-modal__status">
              Status solicitare:
            </h3>
            <FormControl className="solicitation-modal__status">
              <NativeSelect
                value={this.props.eventObject.status}
                onChange={event => this.props.updateStatusValue(event)}
                inputProps={{
                  name: 'status'
                }}
              >
                <option value="">Selecteaza Status</option>
                <option value="nou">Nou</option>
                <option value="desfasurare">In desfasurare</option>
                <option value="rezolvat">Rezolvat</option>
                <option value="respins">Respins</option>
              </NativeSelect>
            </FormControl>
            <div className="admin-form__submit">
              <button
                type="submit"
                className="general-button solicitation-modal__button"
                onClick={this.props.updateStatus}
              >
                Updateaza Status
              </button>
            </div>
            {this.props.showMailButton && (
              <div className="admin-form__submit">
                <button
                  type="submit"
                  className="general-button solicitation-modal__button"
                  onClick={this.openDeleteDialog}
                >
                  Notifica Utilizatorul
                </button>
              </div>
            )}
          </div>
          {this.props.eventObject.document &&
          this.props.eventObject.document.url ? (
            <button className="info-form__button solicitation-modal__document-button">
              <h5
                className="info-form__button-writing"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_API_URL}${this.props.eventObject.document.url}`
                  )
                }
              >
                Vizualizează Document
              </h5>
            </button>
          ) : null}
        </div>
        <DeletePUGCertificates
          opened={this.state.openedDialog}
          handleClose={this.closeDeleteDialog}
          handleDelete={() => {
            this.closeDeleteDialog();
            this.props.handleSendEmail();
          }}
          confirmButtonMessage="Trimite"
          headline="Expeditorul cererii va primi o notificare prin email cu update-ul statusului, doriti sa il notificati prin email?"
        />
      </Modal>
    );
  }
}

SolicitationModal.propTypes = {
  modalIsOpen: bool.isRequired,
  closeModal: func.isRequired,
  eventObject: object.isRequired
};

export default SolicitationModal;
