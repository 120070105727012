import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { registerDispositionsProjectService } from '../../../services/RegisterDispozitionsProjectsService';
import ReactPaginate from 'react-paginate';

class RegisterDispositionsProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newRegisterDocument: {
        title: '',
        document: '',
        date: ''
      },
      existingRegisterDocuments: [],
      deleteRegisterDocuments: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    registerDispositionsProjectService
      .getAll()
      .then(jsonResponse => {
        this.setRegisterState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    registerDispositionsProjectService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setRegisterState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setRegisterState = response => {
    let existingRegisterDocuments = { ...this.state.existingRegisterDocuments };
    let pagination = { ...this.state.pagination };
    existingRegisterDocuments = response.dispositions_projects_registers;
    pagination = response.pagination;

    this.setState({ existingRegisterDocuments });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newRegisterDocument = this.state.newRegisterDocument;
    newRegisterDocument[field] = event.target.value;

    this.setState({
      newRegisterDocument
    });
  };

  onDropDocument = documents => {
    let newRegisterDocument = { ...this.state.newRegisterDocument };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newRegisterDocument.document = documents[0];

      self.setState({ newRegisterDocument });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newRegisterDocument = { ...this.state.newRegisterDocument };
    newRegisterDocument.document = null;

    this.setState({ newRegisterDocument });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newRegisterDocument = { ...this.state.newRegisterDocument };
    newRegisterDocument = {
      title: '',
      document: ''
    };

    this.setState({ newRegisterDocument });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newRegisterDocument.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/decisions_projects_registers/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/decisions_projects_registers/${this.state.newRegisterDocument.id}`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingRegisterDocuments = this.state.existingRegisterDocuments.slice();

        let index = existingRegisterDocuments.findIndex(
          existingregisterDocument =>
            existingregisterDocument.id === jsonResponse.id
        );

        if (index !== -1) {
          existingRegisterDocuments[index] = jsonResponse;
        } else {
          existingRegisterDocuments.push(jsonResponse);
        }

        this.setState({ existingRegisterDocuments });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Documentul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Documentul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'decisions_projects_register[title]',
      this.state.newRegisterDocument.title
    );

    formData.append(
      'decisions_projects_register[add_date]',
      this.state.newRegisterDocument.date
    );

    if (this.state.newRegisterDocument.document) {
      let document = this.state.newRegisterDocument.document;
      formData.append(
        `decisions_projects_register[document]`,
        document,
        document.name
      );
    }

    return formData;
  }

  setRegisterDocumentForEdit = newRegisterDoc => {
    let newRegisterDocument = { ...this.state.newRegisterDocument };
    newRegisterDocument = Object.assign({}, newRegisterDoc);
    this.setState({ newRegisterDocument });

    if (newRegisterDocument.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteRegisterDocuments = { ...this.state.deleteRegisterDocuments };
    deleteRegisterDocuments = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteRegisterDocuments });
  };

  closeDeleteDialog = () => {
    let deleteRegisterDocuments = { ...this.state.deleteRegisterDocuments };
    deleteRegisterDocuments = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteRegisterDocuments });
  };

  handleDelete = () => {
    registerDispositionsProjectService
      .deleteRegisterDocuments(this.state.deleteRegisterDocuments.deleteId)
      .then(() => {
        let existingRegisterDocuments = this.state.existingRegisterDocuments.slice();
        existingRegisterDocuments = existingRegisterDocuments.filter(
          registerDocument =>
            registerDocument.id !== this.state.deleteRegisterDocuments.deleteId
        );
        this.setState({ existingRegisterDocuments });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Documentul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Interes Public
          </h3>
          <h1 className="admin-switch-title default-title">
            {' '}
            Registrul pentru evidența proiectelor de dispozitii
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga un document nou</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Titlu*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newRegisterDocument.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Data*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newRegisterDocument.date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newRegisterDocument.document}
                    documentFilename={
                      this.state.newRegisterDocument.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newRegisterDocument.id
                        ? 'Editează Registru'
                        : 'Adaugă Registru'}
                    </button>
                  </div>
                  {Object.values(this.state.newRegisterDocument) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingRegisterDocuments.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Proiecte cu finantare externa existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingRegisterDocuments.map(
                      registerDocument => (
                        <div
                          className="existing-items__content-card"
                          key={registerDocument.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {registerDocument.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.setRegisterDocumentForEdit(
                                  registerDocument
                                )
                              }
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.openDeleteDialog(registerDocument.id)
                              }
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteRegisterDocuments.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default RegisterDispositionsProject;
