import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const acquisitionsProgramService = {
  getAll,
  createOrUpdate,
  deleteProgram
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/annual_aquisitions_programs/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newProgram) {
  const requestOptions = {
    method: newProgram.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newProgram)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/annual_aquisitions_programs${newProgram.id ? `/${newProgram.id}` : ''}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(program) {
  let formData = new FormData();

  formData.append('annual_acquisitions_program[title]', program.title);
  formData.append('annual_acquisitions_program[add_date]', program.date);

  const document = program.document;

  if (document) {
    if (document.url) {
      formData.append(`annual_acquisitions_program[document]`, document.url);
    } else {
      formData.append(
        `annual_acquisitions_program[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deleteProgram(programId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/annual_aquisitions_programs/${programId}`;

  return axios.delete(url, requestOptionsDelete);
}
