// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class AdministrativeArea extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Suprafata Administrativa
          </h3>
          <h4 className="standard__generic aa-description" id="gp-description">
            Orasul Vicovu de Sus ocupă o suprafaţă de 4242 hectare (0.49% din
            suprafaţa totală a Judeţului Suceava), din care cea mai mare
            suprafată (100%) o reprezintă terenul agricol / neagricol / alte
            forme - 0 Ha.
          </h4>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Dupa forma de proprietate
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                După forma de proprietate, terenul agricol este detinut în
                proportie de peste 88.73% de proprietari privati, în timp ce
                terenul neagricol este detinut într-o proportie de peste 79.54%
                de stat.
              </h4>
              <div className="table">
                <div className="table__row table__row--header">
                  <div className="text">Specificatie</div>
                  <div className="text">
                    <div>Teren Agricol</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">ha</div>
                      <div className="text">%</div>
                    </div>
                  </div>
                  <div className="text">
                    <div>Teren Neagricol</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">ha</div>
                      <div className="text">%</div>
                    </div>
                  </div>
                  <div className="text">
                    <div>Total</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">ha</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Proprietate publica a UAT</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">0</div>
                      <div className="text">0</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">241</div>
                      <div className="text">22.82</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">241</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Proprietate privată a UAT</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">359</div>
                      <div className="text">11.27</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">599</div>
                      <div className="text">56.72</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">958</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Proprietate privată</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2827</div>
                      <div className="text">88.73</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">216</div>
                      <div className="text">20.45</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">3043</div>
                    </div>
                  </div>
                </div>
                <div className="table__row table__row--green">
                  <div className="text text--final">TOTAL</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">3186</div>
                      <div className="text">100</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">1056</div>
                      <div className="text">100</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">4242</div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Din punct de vedere al evolutiei terenului
              </h3>
              <h4 className="standard__generic" id="gp-description">
                Din punct de vedere al evolutiei terenului, în ultimii 5 ani
                structura suprafetei administrative a orasului s-a mentinut
                constantă după cum se poate observa din datele prezentate mai
                jos, mici variatii inregistrându-se la categoria vii si livezi /
                teren agricol / păduri / ape si bălâi etc.
              </h4>
              <div className="table">
                <div className="table__row table__row--header">
                  <div className="text">Categorie de folosinta</div>
                  <div className="text">
                    <div>Suprafata (ha / km)</div>
                    <div className="table__subrow table__subrow--header">
                      <div className="text">2014</div>
                      <div className="text">2013</div>
                      <div className="text">2012</div>
                      <div className="text">2011</div>
                      <div className="text">2010</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Teren agricol, din care:</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">3190</div>
                      <div className="text">3190</div>
                      <div className="text">3190</div>
                      <div className="text">3190</div>
                      <div className="text">3190</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text"> - arabil</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2086</div>
                      <div className="text">2086</div>
                      <div className="text">2086</div>
                      <div className="text">2086</div>
                      <div className="text">2086</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text"> - pasuni si fanete</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">1047</div>
                      <div className="text">1047</div>
                      <div className="text">1064</div>
                      <div className="text">1064</div>
                      <div className="text">1064</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text"> - vii si livezi</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">57</div>
                      <div className="text">57</div>
                      <div className="text">40</div>
                      <div className="text">40</div>
                      <div className="text">40</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    {' '}
                    Păduri si alte terenuri cu vegetatie forestieră
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text"> Ape si balti</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                      <div className="text"> - </div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text"> Teren intravilan</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">1849</div>
                      <div className="text">1849</div>
                      <div className="text">1849</div>
                      <div className="text">1849</div>
                      <div className="text">1849</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Drumuri (km)</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text" />
                      <div className="text" />
                      <div className="text" />
                      <div className="text" />
                      <div className="text" />
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Neproductiv</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text" />
                      <div className="text" />
                      <div className="text" />
                      <div className="text" />
                      <div className="text" />
                    </div>
                  </div>
                </div>
                <div className="table__row table__row--green">
                  <div className="text text--final"> Suprafata totala</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">4242</div>
                      <div className="text text--final">4242</div>
                      <div className="text text--final">4242</div>
                      <div className="text text--final">4242</div>
                      <div className="text text--final">4242</div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="standard__generic" id="gp-description">
                <b>Suprafata agricolă totală</b> este alcatuită din teren arabil
                în cea mai mare parte – 2086 ha (65.%), păsuni si fânete 1047 ha
                (33%) si vii si livezi 57 ha (2%).
              </h4>
              <h4 className="standard__generic" id="gp-description">
                <b>Fondul forestier</b> – nu este cazul
              </h4>
              <h4 className="standard__generic" id="gp-description">
                <b>Luciul de apa</b> – nu este cazul.
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Intravilanul localității
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Terenul intravilan al orasului Sucevita ocupă în prezent o
                suprafaţă de 1849.05 hectare (43% din total suprafată
                administrativă).
              </h4>
              <h4 className="standard__generic" id="gp-description">
                <b>Bilanţ teritorial localitatea VICOVUL DE SUS</b>
              </h4>
              <div className="table">
                <div className="table__row table__row--header">
                  <div className="text">Zone funcţionale</div>
                  <div className="text">
                    <div>2006</div>
                    <div className="table__subrow table__row--header">
                      <div className="text">ha</div>
                      <div className="text">%</div>
                    </div>
                  </div>
                  <div className="text">
                    <div>2014</div>
                    <div className="table__subrow table__row--header">
                      <div className="text">ha</div>
                      <div className="text">%</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Locuinţe şi funcţiuni complementare
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">433.50</div>
                      <div className="text">32.23</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">459.78</div>
                      <div className="text">33.50</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Instituţii de interes public</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">26.11</div>
                      <div className="text">1.94</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">26.11</div>
                      <div className="text">1.90</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Zona de unitati industriale si de depozitare
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">6.77</div>
                      <div className="text">0.51</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">6.77</div>
                      <div className="text">0.49</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Zona de unitati agro-zootehnice</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">1.68</div>
                      <div className="text">0.12</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">1.68</div>
                      <div className="text">0.12</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Zona spatii verzi, sport si agrement
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">22.95</div>
                      <div className="text">1.71</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">22.95</div>
                      <div className="text">1.67</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Căi de comunicaţie rutiere</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">68.68</div>
                      <div className="text">5.10</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">70.05</div>
                      <div className="text">5.10</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Gospodărie comunală, cimitire</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">4.14</div>
                      <div className="text">0.30</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">4.14</div>
                      <div className="text">0.30</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Zona de unitati tehnico - edilitare
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">19.25</div>
                      <div className="text">1.43</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">19.25</div>
                      <div className="text">1.40</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Terenuri arabile</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">762.32</div>
                      <div className="text">56.66</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">762.32</div>
                      <div className="text">55.52</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">
                    Total teritoriu intravilan existent si propus
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">1345.40</div>
                      <div className="text text--final">100.00</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">1373.05</div>
                      <div className="text text--final">100.00</div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>
                <b>Bilanţ teritorial localitatea BIVOLARIE</b>
              </h4>
              <div className="table">
                <div className="table__row table__row--header">
                  <div className="text">Zone funcţionale</div>
                  <div className="text">
                    <div>2006</div>
                    <div className="table__subrow table__row--header">
                      <div className="text">ha</div>
                      <div className="text">%</div>
                    </div>
                  </div>
                  <div className="text">
                    <div>2014</div>
                    <div className="table__subrow table__row--header">
                      <div className="text">ha</div>
                      <div className="text">%</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Locuinţe şi funcţiuni complementare
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">111,75</div>
                      <div className="text">28.00</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">187.07</div>
                      <div className="text">39.28</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Instituţii şi servicii de interes public
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">6.13</div>
                      <div className="text">1.53</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">6.13</div>
                      <div className="text">1.29</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Unităţi Industriale şi depozitare</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2.58</div>
                      <div className="text">0.65</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2.58</div>
                      <div className="text">0.54</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Zona de unitati tehnico - edilitare
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7.25</div>
                      <div className="text">1.82</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">7.25</div>
                      <div className="text">1.52</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">
                    Zona spatii verzi, sport si agrement
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2.22</div>
                      <div className="text">0.57</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2.22</div>
                      <div className="text">0.46</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Căi de comunicaţie şi transport</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">19.13</div>
                      <div className="text">4.80</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">21.07</div>
                      <div className="text">4.42</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Căi de comunicaţie cai ferate</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">4.36</div>
                      <div className="text">1.09</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">4.36</div>
                      <div className="text">0.92</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Gospodărie comunală, cimitire</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2.10</div>
                      <div className="text">0.53</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">2.10</div>
                      <div className="text">0.44</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text">Zonă teren agricol</div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">243.44</div>
                      <div className="text">61.01</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text">243.44</div>
                      <div className="text">51.13</div>
                    </div>
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">
                    Total teritoriu intravilan existent si propus
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">398.96</div>
                      <div className="text text--final">100.00</div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="table__subrow">
                      <div className="text text--final">476.22</div>
                      <div className="text text--final">100.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdministrativeArea;
