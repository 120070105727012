// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class WaterSupply extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Alimentare cu apă si apă uzată
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Sursa de apă
              </h3>
              <h4 className="standard__generic dd-info" id="gp-description">
                In orașul Sucevita , principala sursă de apă o constituie apa
                subterană, alimentarea cu apă realizându-se prin extragerea din
                puțuri forate. Există 4 puțuri forate, insă în prezent doar 4
                funcționează.
              </h4>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Sistem de colectare si depozitare
              </h3>
              <h4 className="standard__generic dd-info" id="gp-description">
                Apa extrasă din puțuri este colectată într-un depozit un
                rezervoare cu o capacitate de 900 mc/, capacitatea totală fiind
                de 900 mc. Potabilizarea apei se face printr-o preclorinare cu
                hipoclorit și trecerea acesteia prin stația de filtrare și
                clorinare înainte de depozitare.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                <b>Stația de tratare</b> a apei, a fost dată în funcțiune în
                anul 2003 cu scopul de a asigura locuitorilor din orasul
                Sucevita apă potabilă. Cu toate acestea, sistemul de alimentare
                cu apă nu funcționează corespunzător, din cauza extinderilor de
                retea facute.
              </h4>
              <h4 className="standard__generic dd-info" id="gp-description">
                <b>Rețeaua de alimentare cu apă</b> are o lungime totală de 12,8
                km și a fost pusă în funcțiune în 2003. Există un număr de 34 de
                cișmele.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Din totalul de 4216 de locuințe, până în acest moment la
                sistemul de alimentare cu apă sunt racordate 1250, adică un
                procent de 31 %.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">Tipuri de utilizatori</div>
                  <div className="text">Nr. racordate la retea</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    <b>POPULATIE</b> - CASE PARTICULARE
                  </div>
                  <div className="text">1250</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    <b>POPULATIE</b> - Contracte individuale la blocuri
                  </div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    {' '}
                    Contracte cu asociatiile de proprietari si locatari
                  </div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    <b>UNITĂŢI</b> - AGENŢI ECONOMICI
                  </div>
                  <div className="text">14</div>
                </div>
                <div className="table__row">
                  <div className="text">
                    <b>UNITĂŢI</b> - SOCIALE (şcoli, grădiniţe, dispensar,
                    piaţă, unitatea de pompieri)
                  </div>
                  <div className="text">15</div>
                </div>
                <div className="table__row table__row--green">
                  <div className="text text--final">TOTAL</div>
                  <div className="text text--final">1279</div>
                </div>
              </div>
              <h3>Consumul de apă potabilă</h3>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">Anul</div>
                  <div className="text">Consum metrii cubi / an</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2010</div>
                  <div className="text">314120</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2011</div>
                  <div className="text">333420</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2012</div>
                  <div className="text">337000</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2013</div>
                  <div className="text">386570</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2014</div>
                  <div className="text">399350</div>
                </div>
              </div>
              <h4 className="standard__generic aa-info" id="gp-description">
                În România, Ordinul ministrului sănătăţii 536/1997 pentru
                aprobarea Normelor de igienă şi a recomandărilor privind mediul
                de viaţă al populaţiei, prevede, între altele, cantitatea minimă
                de apă pe zi pentru un locuitor, care trebuie să fie de minim 50
                litri (în scopul acoperirii necesarului fiziologic, igienei
                individuale şi pregătirii hranei).
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Surse de poluare a apei in oraș
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Poluarea apei subterane se datorează folosirii îngrășămintelor
                chimice în agricultură, existenței unor fose rudimentare
                folosite de gospodăriile locale, dar și datorită structurii
                solului. Elementele cele mai poluante sunt fierul, manganul,
                nitrații și cei mai periculoși nitriții.
              </h4>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Preţurile şi tarifele practicate
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Potrivit prevederilor Hotărarii Consiliului Local Sucevita
                nr.48/26.11.2014 începând cu data de 01.01.2015 se aplică
                următoarele prețuri pentru apa potabilă livrată în orașul Vicovu
                de Sus :
              </h4>
              <h5>- Preț pentru populație – 1.17 lei/mc</h5>
              <h5>- Preț pentru restul de utilizatori – 1.17 lei/mc</h5>
              <h4 className="standard__generic dd-info" id="gp-description">
                Prețurile la apă pentru populație nu conțin TVA, iar pentru
                restul utilizatorilor nu conțin TVA.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Din informatiile analizate, este mai mult decât evident faptul
                că necesarul de apă nu este acoperit și este nevoie de eforturi
                în acest sens, soluțiile fiind conturate de reprezentanții
                autorității locale în proiecte de investiții de tipul :
              </h4>
              <h5>- Extindere front de captare și rețea aducțiune 18.92 l/s</h5>
              <h5>- Reabilitare stație tratare existenta – 1635 mc/zi</h5>
              <h5>
                - Extindere rețea distribuție având în vedere tendința de
                dezvoltare a localității – 20000 ml
              </h5>
              <h5>- Extindere gospodărie Apă – capacitate stocare 900 mc</h5>
              <h5>- Capacitate pompare 1200 mc/zi</h5>
              <h5>
                - Extinderea rețelei de alimentare cu apă pe străzile inființate
                în 2004 , aprox. 3400 ml.
              </h5>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Rețeaua de ape uzate
              </h3>
              <h4 className="standard__generic dd-info" id="gp-description">
                În prezent, nu exista rețea de canalizare.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Autoritatea locală dorește implementarea infintarea sistemului
                de colectare și epurare a apei uzate pe o lungime 25 km, cu
                execuție de racorduri și branșamente - proiect ce urmează a fi
                implementat din aceeași sursă de finanțare.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Rețeaua de colectare a apelor pluviale din orașul Sucevita este
                formată din șanțuri și rigolele de scurgere a apelor pluviale,
                însă acestea sunt parțial colmatate și există riscul ca
                riveranii să fie inundati de fiecare dată când va ploua mai
                abundent.
              </h4>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Energia termică
              </h3>
              <h4 className="standard__generic dd-info" id="gp-description">
                Sistem de termoficare propriu
              </h4>
              <h4 className="standard__generic dd-info" id="gp-description">
                Alimentare cu gaze
              </h4>
              <h4 className="standard__generic dd-info" id="gp-description">
                Incalzire pe lemne
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WaterSupply;
