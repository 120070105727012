import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { draftDecisionsService } from '../../services/DraftDecisionsService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class DraftDecisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      draftDecisions: [],
      searchDraftDecision: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    draftDecisionsService
      .getAll()
      .then(jsonResponse => {
        this.setDraftDecisionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setDraftDecisionsState = response => {
    let draftDecisions = { ...this.state.draftDecisions };
    let pagination = { ...this.state.pagination };
    draftDecisions = response.draft_decisions;
    pagination = response.pagination;

    this.setState({ draftDecisions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchDraftDecision = this.state.searchDraftDecision;
    searchDraftDecision[field] = event.target.value;

    this.setState(
      {
        searchDraftDecision
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    draftDecisionsService
      .search(this.state.searchDraftDecision, pageNumber)
      .then(jsonResponse => {
        this.setDraftDecisionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Proiecte de hotarari</title>
          <meta
            name="description"
            content="Proiecte de hotarari Primaria Sucevita. Aici sunt prezentate proiectele de hotarari ale primariei."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Proiecte de hotarari" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Proiecte de Hotărâri</h4>
          </div>
          <h2 className="standard__main-title">Proiecte de Hotărâri</h2>
        </div>

        <div className="standard__wrapper ">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchDraftDecision.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchDraftDecision.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.draftDecisions.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.draftDecisions.length > 0 ? (
              this.state.draftDecisions.map(draftDecision => (
                <div
                  key={draftDecision.id}
                  className="standard__individual-card"
                >
                  <h3 className="standard__card-title">
                    {draftDecision.title}
                  </h3>
                  <h4 className="standard__card-date">
                    {draftDecision.add_date}
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${draftDecision.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${draftDecision.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Nu sunt rezultate pentru căutare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DraftDecisions;
