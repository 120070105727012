import React, { Component } from 'react';
import '../../resources/StandardPage.css';
import './BirthRegistration.css';
import MetaTags from 'react-meta-tags';

class BirthRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boxesOptions: {
        openedBox1: false,
        openedBox2: false,
        openedBox3: false,
        openedBox4: false,
        openedBox5: false,
        openedBox6: false
      }
    };
  }

  openBox = openBoxType => {
    const currentBox = this.state.boxesOptions[openBoxType];
    let stateBoxes = this.state.boxesOptions;
    stateBoxes = Object.keys(stateBoxes).map(
      option => (stateBoxes[option] = false)
    );

    stateBoxes[openBoxType] = !currentBox;

    this.setState({
      boxesOptions: stateBoxes
    });
  };

  closeBox = closeBoxType => {
    this.setState({ boxesOptions: { closeBoxType: false } });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Intocmirea Actului de nastere</title>
          <meta
            name="description"
            content="Intocmirea Actului de nastere Primaria Sucevita. Aici sunt prezentate detaliile cu privire la Intocmirea Actului de nastere."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Intocmirea Actului de nastere" />
        </MetaTags>

        <div className="birth-registration__page">
          <div className="standard__first-section">
            <div className="standard__title-box">
              <h4 className="standard__generic">Servicii > </h4>
              <h4 className="standard__generic standard__title-page">
                Înregistrare naștere
              </h4>
            </div>
            <h2 className="standard__main-title birth-registration__main-title">
              Înregistrare naștere
            </h2>
          </div>
          <div className="birth-registration__cards">
            <div className="standard__card birth-registration__card">
              <div className="birth-registration__card-title">
                <h3
                  className="standard__card-title"
                  onClick={event => this.openBox('openedBox1')}
                >
                  Întocmirea actului de naștere
                </h3>
                {this.state.boxesOptions.openedBox1 ? (
                  <span>
                    <img
                      className="birth-registration__arrow-icon"
                      src="/images/icons/up-arrow.png"
                      alt="up-arrow"
                      onClick={() => this.closeBox('openedBox2')}
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      className="birth-registration__arrow-icon"
                      src="/images/icons/down-arrow.png"
                      alt="down-arrow"
                      onClick={event => this.openBox('openedBox1')}
                    />
                  </span>
                )}
              </div>
              <h4
                className={
                  this.state.boxesOptions.openedBox1
                    ? 'standard__card-description-details'
                    : 'standard__card-description'
                }
              >
                Întocmirea actului de naştere se face de personalul cu atribuţii
                de stare civilă din cadrul serviciului public comunitar local de
                evidenţă a persoanelor sau din cadrul primăriei unităţii
                administrativ-teritoriale în a cărei rază s-a produs evenimentul
                ori, după caz, de către şeful misiunii diplomatice sau al
                oficiului consular de carieră.
              </h4>
              {this.state.boxesOptions.openedBox1 ? (
                <button
                  onClick={event => this.openBox('openedBox1')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai putin
                  </h4>
                </button>
              ) : (
                <button
                  onClick={event => this.openBox('openedBox1')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai mult
                  </h4>
                </button>
              )}
            </div>

            <div className="standard__card birth-registration__card">
              <div className="birth-registration__card-title">
                <h3
                  className="standard__card-title"
                  onClick={event => this.openBox('openedBox2')}
                >
                  Declararea nasterii
                </h3>
                {this.state.boxesOptions.openedBox2 ? (
                  <span>
                    <img
                      className="birth-registration__arrow-icon"
                      src="/images/icons/up-arrow.png"
                      alt="up-arrow"
                      onClick={() => this.closeBox('openedBox2')}
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      className="birth-registration__arrow-icon"
                      src="/images/icons/down-arrow.png"
                      alt="down-arrow"
                      onClick={event => this.openBox('openedBox2')}
                    />
                  </span>
                )}
              </div>
              <h4
                className={
                  this.state.boxesOptions.openedBox2
                    ? 'standard__card-description-details'
                    : 'standard__card-description'
                }
              >
                Declararea naşterii se face în termen de 30 de zile pentru
                copilul născut viu şi în termen de 3 zile pentru copilul născut
                mort. Termenele se socotesc de la data naşterii. În cazul în
                care copilul născut viu a decedat înăuntrul termenului de 30 de
                zile, declararea naşterii se face în termen de 24 de ore de la
                data decesului.
              </h4>
              {this.state.boxesOptions.openedBox2 ? (
                <button
                  onClick={event => this.openBox('openedBox2')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai putin
                  </h4>
                </button>
              ) : (
                <button
                  onClick={event => this.openBox('openedBox2')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai mult
                  </h4>
                </button>
              )}
            </div>

            <div className="standard__card birth-registration__card">
              <div className="birth-registration__card-title">
                <h3
                  className="standard__card-title"
                  onClick={event => this.openBox('openedBox3')}
                >
                  Persoanele obligate la declaratia de nastere
                </h3>
                {this.state.boxesOptions.openedBox3 ? (
                  <span>
                    <img
                      className="birth-registration__arrow-icon"
                      src="/images/icons/up-arrow.png"
                      alt="up-arrow"
                      onClick={() => this.closeBox('openedBox3')}
                    />
                  </span>
                ) : (
                  <span>
                    <img
                      className="birth-registration__arrow-icon"
                      src="/images/icons/down-arrow.png"
                      alt="down-arrow"
                      onClick={event => this.openBox('openedBox3')}
                    />
                  </span>
                )}
              </div>
              <h4
                className={
                  this.state.boxesOptions.openedBox3
                    ? 'standard__card-description-details'
                    : 'standard__card-description'
                }
              >
                Au obligaţia de a face declaraţia de naştere oricare dintre
                părinţi, iar dacă, din diferite motive, nu o pot face, obligaţia
                declarării revine medicului, persoanelor care au fost de faţă la
                naştere, asistentului social sau, după caz, persoanei cu
                atribuţii de asistenţă socială din unitatea sanitară în care a
                avut loc naşterea sau oricărei persoane care a luat cunoştinţă
                despre naşterea copilului.
              </h4>
              {this.state.boxesOptions.openedBox3 ? (
                <button
                  onClick={event => this.openBox('openedBox3')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai putin
                  </h4>
                </button>
              ) : (
                <button
                  onClick={event => this.openBox('openedBox3')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai mult
                  </h4>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="birth-registration__content">
          <h3 className="standard__main-title birth-registration__main-title">
            Transcrierea naşterii copilului din căsătorie
          </h3>
          <div className="birth-registration__documents-list">
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">1</h4>
              <h4 className="birth-registration__item">
                Certificatul medical constatator al naşterii
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">2</h4>
              <h4 className="birth-registration__item">
                Certificatul de căsătorie al părinţilor copilului
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Actul de identitate al mamei şi al declarantului
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Certificatul de căsătorie al părinţilor copilului
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Certificatele de cetatenie ale parintilor
              </h4>
            </div>
          </div>
          <div className="birth-registration__info-box">
            <h3 className="standard__ordinary-title birth-registration__ordinary-title">
              Copiii născuţi de la data de 30.06.2016
            </h3>
            <h4 className="standard__card-description-details">
              Pentru copiii născuţi de la data de 30.06.2016, în lipsa actului
              de identitate al mamei, întocmirea actului de naştere se face pe
              baza celorlalte documente şi a documentului întocmit de
              reprezentantul poliţiei cu ocazia verificărilor efectuate la
              solicitarea unităţii sanitare în care a avut loc naşterea, cu
              privire la datele de identificare a mamei, şi a verificărilor în
              registrele de stare civilă sau în Registrul Naţional de Evidenţă a
              Persoanelor.
            </h4>
          </div>
        </div>
        <div className="birth-registration__background">
          <h3 className="standard__main-title birth-registration__main-title">
            Transcrierea naşterii copilului din afara căsătoriei
          </h3>
          <div className="birth-registration__documents-list">
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">1</h4>
              <h4 className="birth-registration__item">
                Certificatul medical constatator al naşterii
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">2</h4>
              <h4 className="birth-registration__item">
                Certificatul de nastere al mamei
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Actul de identitate al mamei şi al declarantului
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Certificatele de cetatenie ale parintilor
              </h4>
            </div>
          </div>
          <div className="birth-registration__info-box birth-registration__info-box-light">
            <h3 className="standard__ordinary-title birth-registration__ordinary-title">
              Copiii născuţi de la data de 30.06.2016
            </h3>
            <h4 className="standard__card-description-details">
              Pentru copiii născuţi de la data de 30.06.2016, întocmirea actului
              de naştere al copilului, în cazul în care naşterea mamei nu este
              înregistrată, se face pe baza declaraţiei scrise a persoanelor
              îndreptăţite, conform legii, unui proces-verbal privind
              identitatea declarată de mamă, semnat de reprezentantul direcţiei
              generale de asistenţă socială şi protecţia copilului, de
              reprezentantul poliţiei şi de cel al unităţii sanitare, a
              certificatului medical constatator al naşterii şi a actului de
              identitate al declarantului. În acest caz, numele de familie şi
              prenumele copilului se stabilesc prin dispoziţie emisă în termen
              de 5 zile de la data solicitării de către primarul unităţii
              administrativ-teritoriale unde se înregistrează naşterea.
            </h4>
          </div>
        </div>
        <div className="birth-registration__child-transcripts">
          <div className="birth-registration__child-type">
            <h3 className="standard__general-description birth-registration__child-transcript-info">
              Transcrierea copilului născut mort
            </h3>
            <h5 className="standard__general-description birth-registration__child-transcript-info">
              Termen de înregistrare
            </h5>
            <h4 className="standard__general-description birth-registration__child-transcript-info">
              3 zile de la data naşterii
            </h4>
          </div>
          <div className="birth-registration__child-type birth-registration__switch-dark">
            <h3 className="standard__general-description birth-registration__switch">
              Transcrierea copilului născut viu care a decedat în intervalul
              celor 15 zile
            </h3>
            <h5 className="standard__general-description birth-registration__switch">
              Termen de înregistrare
            </h5>
            <h4 className="standard__general-description birth-registration__switch">
              24 de ore de la data decesului
            </h4>
          </div>
        </div>
        <div className="birth-registration__background">
          <h4 className="standard__ordinary-title birth-registration__documents-info">
            Recunoaşterea copilului din afara căsătoriei şi încuviinţarea
            purtării de nume
          </h4>
          <p className="standard__description birth-registration__documents-info">
            Copilul conceput şi născut în afara căsătoriei poate fi recunoscut
            de către tatăl său.
          </p>
          <p className="standard__description birth-registration__documents-info">
            Recunoaşterea poate fi făcută prin declaraţie la serviciul public
            comunitar local de evidenţă a persoanelor unde a fost/sau este
            înregistrată naşterea copilului, prin înscris autentic sau prin
            testament.
          </p>
          <h4 className="standard__description birth-registration__documents-info">
            Numele copilului din afara căsătoriei:
          </h4>
          <ul className="birth-registration__second-list">
            <li className="standard__description">
              Copilul din afara căsătoriei ia numele de familie al aceluia
              dintre părinţi faţă de care filiaţia a fost mai întâi stabilită
            </li>
            <li className="standard__description">
              În cazul în care filiaţia a fost stabilită ulterior şi faţă de
              celălalt părinte, copilul, prin acordul părinţilor, poate lua
              numele de familie al părintelui faţă de care şi-a stabilit
              filiaţia ulterior sau numele reunite ale acestora
            </li>
            <li className="standard__description">
              Noul nume de familie al copilului se declară de către părinţi,
              împreună, la serviciul public comunitar local de evidenţă a
              persoanelor la care a fost înregistrată naşterea
            </li>
            <li className="standard__description">
              În lipsa acordului părinţilor instanţa de tutelă hotărăşte şi
              comunică de îndată hotărârea rămasă definitivă la serviciul public
              comunitar local de evidenţă a persoanelor unde a fost înregistrată
              naşterea
            </li>
            <li className="standard__description">
              În cazul în care copilul şi-a stabilit filiaţia în acelaşi timp
              faţă de ambii părinţi, aceştia declară împreună numele de familie
              al copilului la serviciul public comunitar local de evidenţă a
              persoanelor la care a fost înregistrată naşterea iar în lipsa
              acordului cu privire la numele de familie instanţa de tutelă
              hotărăşte şi comunică de îndată hotărârea rămasă definitivă
            </li>
          </ul>
        </div>
        <div className="birth-registration__content">
          <h3 className="standard__main-title birth-registration__main-title">
            {' '}
            Situaţii în care declararea copilului se face după expirarea
            termenelor prevăzute de lege
          </h3>
          <div className="birth-registration__info-box birth-registration__info-box-light">
            <h3 className="standard__ordinary-title birth-registration__ordinary-title">
              {' '}
              După împlinirea termenului de 30 de zile de la data naşterii
            </h3>
            <h4 className="standard__card-description-details">
              După împlinirea termenului de 30 de zile de la data naşterii,
              întocmirea actului de naştere se face la cererea scrisă a
              declarantului, cu aprobarea primarului unităţii
              administrativ-teritoriale în a cărei rază s-a produs evenimentul,
              cu avizul conform al serviciului public comunitar judeţean de
              evidenţă a persoanelor sau, după caz, al Direcţiei Generale de
              Evidenţă a Persoanelor a Municipiului Bucureşti.
            </h4>
          </div>
          <h3 className="standard__main-title birth-registration__main-title">
            {' '}
            Transcrierea naşterii se realizează în termen de 90 de zile de la
            data solicitării
          </h3>
          <div className="birth-registration__documents-list">
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">1</h4>
              <h4 className="birth-registration__item">
                Cerere pentru înregistrarea naşterii
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">2</h4>
              <h4 className="birth-registration__item">
                Certificatul medical constatator al naşterii
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Certificatul de căsătorie al părinţilor copilului
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Actele de identitate ale părinţilor
              </h4>
            </div>
            <div className="birth-registration__document-box">
              <h4 className="birth-registration__item">3</h4>
              <h4 className="birth-registration__item">
                Expertiză medico-legală cu privire la data naşterii, în format
                lună, zi, şi sexul persoanei, dacă nu există certificat medical
                constatator al naşterii
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BirthRegistration;
