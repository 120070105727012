import React from 'react';
import '../StandardPage/StandardPage.css';
import { registerDecisionsProjectService } from '../../services/RegisterDecisionsProjectsService';

class UserRegisterDecisionsProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pagination: {}
    };
  }

  componentWillMount() {
    registerDecisionsProjectService
      .getAll()
      .then(jsonResponse => {
        this.setAdministrativeActsInfoState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    registerDecisionsProjectService
      .getAll(selectedPage.selectedPage + 1)
      .then(jsonResponse => {
        this.setAdministrativeActsInfoState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setAdministrativeActsInfoState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.decisions_projects_registers;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  render() {
    return (
      <div className="standard">
        <title>Documente de interes public</title>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Documente</h4>
          </div>
          <h2 className="standard__main-title">
            Registrul pentru evidența proiectelor de hotarari
          </h2>
        </div>
        <div className="standard__wrapper">
          <ul className="monitor__list">
            <h3 className="monitor__subtitle">
              Din data de 01.06.2021, Monitorul Oficial Local al comunei
              Sucevita poate fi vizualizat{' '}
              <a
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                aici
              </a>
              .
            </h3>
          </ul>
          <div className="standard__cards">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UserRegisterDecisionsProjects;
