import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { verbalSessionsService } from '../../services/VerbalSessionsService';
import ReactPaginate from 'react-paginate';
import '../StandardPage/StandardPage.css';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class VerbalSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verbalSessions: [],
      searchVerbalSession: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    verbalSessionsService
      .getAll()
      .then(jsonResponse => {
        this.setVerbalSessionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };
  // handlePageClick = selectedPage => {
  //   if (this.state.searchVerbalSession.title) {
  //     this.search(selectedPage.selected + 1);
  //   } else {
  //     verbalSessionsService
  //       .getAll(selectedPage.selected + 1)
  //       .then(jsonResponse => {
  //         this.setVerbalSessionsState(jsonResponse);
  //       })
  //       .catch(error => console.log(error));
  //   }
  // };

  setVerbalSessionsState = response => {
    let verbalSessions = { ...this.state.verbalSessions };
    let pagination = { ...this.state.pagination };
    verbalSessions = response.verbal_sessions;
    pagination = response.pagination;

    this.setState({ verbalSessions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchVerbalSession = this.state.searchVerbalSession;
    searchVerbalSession[field] = event.target.value;

    this.setState(
      {
        searchVerbalSession
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    verbalSessionsService
      .search(this.state.searchVerbalSession, pageNumber)
      .then(jsonResponse => {
        this.setVerbalSessionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Procese Verbale Sedinte</title>
          <meta
            name="description"
            content="Procese Verbale Sedinte Sucevita. Aici sunt prezentate procesele verbale din Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Procese Verbale Sedinte" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Procese Verbale Ședințe</h4>
          </div>
          <h2 className="standard__main-title">Procese Verbale Ședințe</h2>
        </div>
        <div>
          <div className="standard__wrapper">
            <div className="standard__input-box standard__mp-input-box">
              <input
                className="standard__input standard__mp-input"
                label="Cautare Document"
                placeholder="Căutare Document"
                name="title"
                ovalue={this.state.searchVerbalSession.title}
                onChange={this.updateField}
              />
              <img
                src="/images/icons/search.svg"
                className="standard__search-icon mariage-publications__search-icon"
                alt="search-icon"
                onClick={() => this.search()}
              />
              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.searchVerbalSession.archiveYear}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'archiveYear'
                    }}
                  >
                    {this.state.archiveYears.map(archive => (
                      <option value={archive.value}>{archive.name}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
            <div
              className={
                'standard__cards ' +
                (this.state.verbalSessions.length <= 2
                  ? 'standard__projects-wrapper-few'
                  : '')
              }
            >
              {this.state.verbalSessions.length > 0 ? (
                this.state.verbalSessions.map(verbalSession => (
                  <div
                    className="standard__individual-card"
                    key={verbalSession.id}
                  >
                    <h3 className="standard__card-title">
                      {verbalSession.title}
                    </h3>
                    <h4 className="standard__card-date">
                      {verbalSession.add_date}
                    </h4>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${verbalSession.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${verbalSession.document.url}`
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Nu sunt rezultate pentru căutare.
                  </p>
                </div>
              )}
            </div>
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default VerbalSessions;
