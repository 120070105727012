import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import MetaTags from 'react-meta-tags';

class ChildAllowances extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Indemnizatii crestere copil</title>
          <meta
            name="description"
            content="Indemnizatii crestere copil Primaria Sucevita. Aici sunt prezentate detaliile cu privire la Indemnizatii crestere copil."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Indemnizatii crestere copil" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Asistență socială </h4>
          </div>
          <h2 className="standard__main-title">Indemnizații creștere copil</h2>
        </div>

        <div className="standard__wrapper allocations-wrapper">
          <div className="heating-aids">
            <div className="heating-aids__left-side">
              <h3 className="standard__submitted-title sa-documents-title">
                Acte necesare intocmirii dosarului indemnizatiei crestere copil
              </h3>
              <ol className="standard__document-list sa-list">
                <li className="standard__list-item">Cerere Tip</li>
                <li className="standard__list-item">
                  Copie cerere de suspendarea activitatii, cf. cu originalul
                </li>
                <li className="standard__list-item">
                  Dispozitie de suspendare
                </li>
                <li className="standard__list-item">
                  Adeverinta de la angajator cu stagiul de cotizare si cu
                  veniturile realizate in ultimele 12 luni anterior datei
                  nasterii copilului
                </li>
                <li className="standard__list-item">
                  Copie certificat nastere copil
                </li>
                <li className="standard__list-item">
                  Copie certificat casatorie
                </li>
                <li className="standard__list-item">Copii C.I. parinti</li>
                <li className="standard__list-item">
                  <b>
                    Adeverinta cu stagiul de cotizare de la casa de pensii
                    Suceava
                  </b>
                </li>
                <li className="standard__list-item">
                  Copii dupa toate concediile medicale - cf. cu originalul
                </li>
                <li className="standard__list-item">Dosar cu sina</li>
              </ol>
              <h3 className="standard__submitted-title sa-documents-title">
                ACTE NECESARE ÎNTOCMIRII DOSARULUI DE INDEMNIZAȚIE CREȘTERE
                COPIL 2 ANI/3 ANI (IN CAZUL COPILULUI CU HANDICAP) PENTRU MAMA
              </h3>
              <ol className="standard__document-list sa-list">
                <li className="standard__list-item">Cerere Tip</li>
                <li className="standard__list-item">
                  CERERE CATRE ANGAJATOR DE SUSPENDARE A CONTRACTULUI DE MUNCA/
                  ȘOMAJ DE SOLICITARE A INDEMNIZATIEI CRESTERE COPIL, DACA E
                  COPIE CU VIZA PENTRU CONFORMITATE
                </li>
                <li className="standard__list-item">
                  DISPOZITIE DE SUSPENDARE/ÎNCETARE A CONTRACTULUI DE MUNCA/ A
                  PLĂȚII
                </li>
                <li className="standard__list-item">
                  COPIE CERTIFICATE MEDICALE CU VIZA DE CONFORMITATE PE FIECARE
                  PAGINA PENTRU CEI ANGAJATI SI CARE SOLICITA SUSPENDAREA
                </li>
                <li className="standard__list-item">
                  CARTEA DE IDENTITATE PARINTI
                </li>
                <li className="standard__list-item">CERTIFICAT CASATORIE</li>
                <li className="standard__list-item">
                  - CERTIFICATE NASTERE COPII
                </li>
                <li className="standard__list-item">
                  Copie certificat de încadrare în grad de handicap, după caz
                </li>
                <li className="standard__list-item">EXTRAS CONT</li>
              </ol>

              <h3 className="standard__submitted-title sa-documents-title">
                ACTE NECESARE ÎNTOCMIRII DOSARULUI DE INDEMNIZAȚIE CREȘTERE
                COPIL 2 ANI/ 3 ANI 9 IN CAZUL COPILULUI CU HANDICAP) PENTRU TATA
              </h3>
              <ol className="standard__document-list sa-list">
                <li className="standard__list-item">Cerere Tip</li>
                <li className="standard__list-item">
                  CERERE CATRE ANGAJATOR DE SUSPENDARE A CONTRACTULUI DE MUNCA,
                  DACA E COPIE CU VIZA PENTRU CONFORMITATE
                </li>
                <li className="standard__list-item">
                  DISPOZITIE DE SUSPENDARE A CONTRACTULUI DE MUNCA
                </li>
                <li className="standard__list-item">
                  ADEVERINTA DE LA CASA DE SĂNĂTATE SUCEAVA DIN CARE SĂ REZULTE
                  CĂ SOȚIA NU A BENEFICIAT ÎN ULTIMELE 12 LUNI DE CONCEDIU
                  MEDICAL
                </li>
                <li className="standard__list-item">
                  CARTEA DE IDENTITATE PARINTI
                </li>
                <li className="standard__list-item">CERTIFICAT CASATORIE</li>
                <li className="standard__list-item">
                  CERTIFICATE NASTERE COPII
                </li>
                <li className="standard__list-item">
                  Copie certificat de încadrare în grad de handicap, după caz
                </li>
                <li className="standard__list-item">EXTRAS CONT</li>
              </ol>

              <h3 className="standard__submitted-title sa-documents-title">
                PRELUNGIRE SUSPENDARE ICC PENTRU MAMA
              </h3>
              <ol className="standard__document-list sa-list">
                <li className="standard__list-item">Cerere Tip</li>
                <li className="standard__list-item">
                  CERERE CATRE ANGAJATOR DE PRELUNGIRE A SUSPENDARII APROBATA,
                  CU VIZA PENTRU CONFORMITATE DACA E COPIE
                </li>
                <li className="standard__list-item">
                  DECIZIE DE PRELUNGIRE A SUSPENDARII
                </li>
                <li className="standard__list-item">
                  ADEVERINTA DE LA ANGAJATOR CA NU A BENEFICIAT DE CONCEDIU IN
                  ULTIMELE 12 LUNI
                </li>
                <li className="standard__list-item">
                  CARTEA DE IDENTITATE PARINTI
                </li>
                <li className="standard__list-item">CERTIFICAT CASATORIE</li>
                <li className="standard__list-item">
                  CERTIFICATE NASTERE COPII
                </li>
                <li className="standard__list-item">
                  CERTIFICATE DE NASTERE COPII
                </li>
                <li className="standard__list-item">EXTRAS CONT</li>
              </ol>

              <h3 className="standard__submitted-title sa-documents-title">
                PRELUNGIRE SUSPENDARE ICC PENTRU TATA
              </h3>
              <ol className="standard__document-list sa-list">
                <li className="standard__list-item">Cerere Tip</li>
                <li className="standard__list-item">
                  CERERE CATRE ANGAJATOR DE PRELUNGIRE A SUSPENDARII APROBATA,
                  CU VIZA PENTRU CONFORMITATE DACA E COPIE
                </li>
                <li className="standard__list-item">
                  DECIZIE DE PRELUNGIRE A SUSPENDARII
                </li>
                <li className="standard__list-item">
                  ADEVERINTA DE LA CASA DE SĂNĂTATE SUCEAVA DIN CARE SĂ REZULTE
                  CĂ SOȚIA NU A BENEFICIAT ÎN ULTIMELE 12 LUNI DE CONCEDIU
                  MEDICAL
                </li>
                <li className="standard__list-item">
                  CARTEA DE IDENTITATE PARINTI
                </li>
                <li className="standard__list-item">CERTIFICAT CASATORIE</li>
                <li className="standard__list-item">
                  CERTIFICATE NASTERE COPII
                </li>
                <li className="standard__list-item">
                  CERTIFICATE DE NASTERE COPII
                </li>
                <li className="standard__list-item">EXTRAS CONT</li>
              </ol>
            </div>
            <div className="heating-aids__right-side allocations-right-side">
              <h3 className="standard__submitted-title sa-documents-title standard__child-allowances-title">
                Formulare necesare
              </h3>
              <div className="standard-page__section-forms">
                <div className="standard-page__section-forms__download">
                  <DownloadCard
                    titleColor="allocations-download-title"
                    backgroundColor="allocations-download-card"
                    flexClass="allocations-card-width"
                    title="Cerere Indemnizatie Crestere Copil"
                    url="/documents/cerere-formular-indemnizatie-crestere-copil.pdf"
                  />
                </div>
                <div className="standard-page__section-forms__download">
                  <DownloadCard
                    titleColor="allocations-download-title"
                    backgroundColor="allocations-download-card"
                    flexClass="allocations-card-width"
                    title="Adeverinta Angajator Indemnizatie Crestere Copil"
                    url="/documents/ICC-Formular-ADEVERINTA-angajator-indemnizatie-crestere-copil.pdf"
                  />
                </div>
                <div className="standard-page__section-forms__download">
                  <DownloadCard
                    titleColor="allocations-download-title"
                    backgroundColor="allocations-download-card"
                    flexClass="allocations-card-width"
                    title="Cerere Prelungire Indemnizatie Crestere Copil"
                    url="/documents/Cerere-prelungire-indemnizatie-crestere-copil.pdf"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChildAllowances;
