import React from 'react';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import ReactPaginate from 'react-paginate';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { organizationRegulamentService } from '../../../services/OrganizationRegulamentService';

class OrganizationRegulament extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledDocument: false,
      newRegulament: {
        title: '',
        document: '',
        add_date: ''
      },
      existingRegulaments: [],
      deleteRegulament: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };
  }
  componentWillMount() {
    organizationRegulamentService
      .getAll()
      .then(response => {
        this.setRegulamentState(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setRegulamentState = response => {
    let existingRegulaments = this.state.existingRegulaments;
    existingRegulaments = response.organization_regulations;
    this.setState({
      existingRegulaments
    });
    let pagination = { ...this.state.pagination };
    pagination = response.pagination;
    this.setState({
      pagination
    });
  };
  handlePageClick = currentPage => {
    organizationRegulamentService
      .getAll(currentPage.selected + 1)
      .then(response => {
        this.setRegulamentState(response);
      })
      .catch(error => console.log(error));
  };
  updateField = event => {
    const field = event.target.name;
    let newRegulament = { ...this.state.newRegulament };
    newRegulament[field] = event.target.value;
    this.setState({
      newRegulament
    });
  };
  resetForm = () => {
    let newRegulament = { ...this.state.newRegulament };
    newRegulament = {
      title: '',
      document: ''
    };
    this.setState({ newRegulament });
    this.setState({ disabledDocument: false });
  };
  openDeleteDialog = id => {
    let deleteRegulament = { ...this.state.deleteRegulament };
    deleteRegulament = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteRegulament });
  };

  closeDeleteDialog = () => {
    let deleteRegulament = { ...this.state.deleteRegulament };
    deleteRegulament = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteRegulament });
  };
  handleFormSubmit = event => {
    event.preventDefault();

    organizationRegulamentService
      .createOrUpdate(this.state.newRegulament)
      .then(response => {
        this.resetForm();

        let existingRegulaments = this.state.existingRegulaments;

        let index = existingRegulaments.findIndex(
          existingRegulaments => existingRegulaments.id === response.id
        );

        if (index !== -1) {
          existingRegulaments[index] = response;
        } else {
          existingRegulaments.push(response);
        }

        this.setState({
          existingRegulaments
        });

        this._notificationSystem.addNotification({
          message: `Regulamentul a fost ${
            this.state.newRegulament.id ? 'editat' : 'adaugat'
          } cu success.`,
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };
  onDropDocument = documents => {
    let newRegulament = { ...this.state.newRegulament };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newRegulament.document = documents[0];

      self.setState({ newRegulament });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newRegulament = { ...this.state.newRegulament };
    newRegulament.document = null;

    this.setState({ newRegulament });
    this.setState({ disabledDocument: false });
  };

  setRegulamentForEdit = regulament => {
    let newRegulament = { ...this.state.newRegulament };
    newRegulament = Object.assign({}, regulament);
    this.setState({ newRegulament });

    if (newRegulament.document && newRegulament.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  handleDelete = () => {
    organizationRegulamentService
      .deleteRegulament(this.state.deleteRegulament.deleteId)
      .then(() => {
        let existingRegulaments = this.state.existingRegulaments.slice();
        existingRegulaments = existingRegulaments.filter(
          regulament => regulament.id !== this.state.deleteRegulament.deleteId
        );
        this.setState({ existingRegulaments });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Regulamentul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la stergere, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Regulamente de organizare
          </h3>
          <h1 className="admin-switch-title default-title">Regulamente</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adauga un regulament de organizare
                </h2>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu document*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newRegulament.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Data document*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.newRegulament.add_date}
                  />
                </div>

                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newRegulament.document}
                    documentFilename={
                      this.state.newRegulament.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newRegulament.id
                        ? 'Editează Regulament'
                        : 'Adaugă Regulament'}
                    </button>
                  </div>
                  {Object.values(this.state.newRegulament) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingRegulaments.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Regulamente de organizare existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingRegulaments.map(regulament => (
                      <div
                        className="existing-items__content-card"
                        key={regulament.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {regulament.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setRegulamentForEdit(regulament)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(regulament.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <DeletePUGCertificates
                    opened={this.state.deleteRegulament.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest regulament?"
                    aditionalText="Dupa stergere, regulamentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default OrganizationRegulament;
