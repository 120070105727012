import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { transparentEarningService } from '../../services/TransparencyEarningsService';

class TransparentEarnings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pagination: {}
    };
  }

  componentWillMount() {
    transparentEarningService
      .getAll()
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.setTransparentEarningState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    transparentEarningService
      .getAll(selectedPage.selectedPage + 1)
      .then(jsonResponse => {
        this.setTransparentEarningState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setTransparentEarningState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.public_interests;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Transparență Venituri Salariale</title>
          <meta
            name="description"
            content="Transparenta Venituri Salariale Sucevita. Aici este prezentata transparenta veniturilor salariale din Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Transparenta Venituri Salariale" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">
              Transparență Venituri Salariale
            </h4>
          </div>
          <h2 className="standard__main-title">
            Situația drepturilor salariale stabilite potrivit legii
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__cards">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${document.document.url}`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TransparentEarnings;
