// @flow

import React, { Component } from 'react';
import './App.css';

import {
  createMuiTheme,
  MuiThemeProvider,
  createGenerateClassName
} from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Contact from './containers/Contact/Contact.jsx';
import UrbanismCertificate from './containers/UrbanismCertificate/UrbanismCertificate';
import ConstructionPermits from './containers/ConstructionPermits/ConstructionPermits';
// import PUG from './containers/PUG/PUG';
import HeatingAids from './containers/HeatingAids/HeatingAids';
import EducationalStimulents from './containers/EducationalStimulents/EducationalStimulents';
import SocialAid from './containers/SocialAid/SocialAid';
import Allocations from './containers/Allocations/Allocations';
import ChildAllowances from './containers/ChildAllowances/ChildAllowances';
import Lead from './containers/Lead/Lead';
import LocalConcilors from './containers/LocalConcilors/LocalConcilors';
import Login from './containers/Login/Login';
import Admin from './containers/Admin/Home/Admin';
import Homepage from './containers/Homepage/Homepage';
// import AdminPUGCertificate from './containers/Admin/PUGCertificate/PUGCertificate';
// import AdminPUGConstruction from './containers/Admin/PUGConstruction/PUGConstruction';
import AdminAnouncements from './containers/Admin/Anouncements/Anouncements';
import AdminEvent from './containers/Admin/Event/Event';
import AdminOngoingProjects from './containers/Admin/OngoingProjects/OngoingProjects';
import AdminCompletedProjects from './containers/Admin/CompletedProjects/CompletedProjects';
import AdminExternalProjects from './containers/Admin/ExternalProjects/ExternalProjects';
import AdminSubmittedProjects from './containers/Admin/SubmittedProjects/SubmittedProjects';
import AdminLawyer from './containers/Admin/Lawyer/Lawyer';
import AdminAcquisitions from './containers/Admin/Acquisitions/Acquisitions';
import AdminAdoptedDecisions from './containers/Admin/AdoptedDecisions/AdoptedDecisions';
import AdminMajorProvisions from './containers/Admin/MajorProvisions/MajorProvisions';
import AdminDraftDecisions from './containers/Admin/DraftDecisions/DraftDecisions';
import AdminConvocationAgenda from './containers/Admin/ConvocationAgenda/ConvocationAgenda';
import AdminVerbalSessions from './containers/Admin/VerbalSessions/VerbalSessions';
import AdminLocalBudget from './containers/Admin/LocalBudget/LocalBudget';
import AdminFinancialSituation from './containers/Admin/FinancialSituation/FinancialSituation';
import SubmittedProjects from './containers/SubmittedProjects/SubmittedProjects';
import OngoingProjects from './containers/OngoingProjects/OngoingProjects';
import ExternalProjects from './containers/ExternalProjects/ExternalProjects';
import CompletedProjects from './containers/CompletedProjects/CompletedProjects';
import HumanResources from './containers/HumanResources/HumanResources';
import Lawyer from './containers/Lawyer/Lawyer';
import DraftDecisions from './containers/DraftDecisions/DraftDecisions';
import AdoptedDecisions from './containers/AdoptedDecisions/AdoptedDecisions';
import MajorProvision from './containers/MajorProvisions/MajorProvisions';
import ConvocationAgenda from './containers/ConvocationAgenda/ConvocationAgenda';
import VerbalSessions from './containers/VerbalSessions/VerbalSessions';
import Acquisitions from './containers/Acquisitions/Acquisitions';
import EventPage from './containers/EventPage/EventPage';
import AdminSocialAid from './containers/Admin/SocialAid/SocialAid';
import AdminHumanResources from './containers/Admin/HumanResources/HumanResources';
import MariagePublicationsAdmin from './containers/Admin/MariagePublications/MariagePublications';
import MariagePublications from './containers/MariagePublications/MariagePublications';
import BirthRegistration from './containers/BirthRegistration/BirthRegistration';
import DeathRecord from './containers/DeathRecord/DeathRecord';
import MariageRecord from './containers/MariageRecord/MariageRecord';
import CivilStatus from './containers/CivilStatus/CivilStatus';
import ForeignCivilStatus from './containers/ForeignCivilStatus/ForeignCivilStatus';
import GeneralPresentation from './containers/GeneralPresentation/GeneralPresentation';
import AgriculturalRegister from './containers/AgriculturalRegister/AgriculturalRegister';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer.jsx';
import AuthenticationService from './services/AuthenticationService';
import MenuAdmin from './components/HeaderAdmin/MenuAdmin/MenuAdmin';
import Announcements from './containers/Announcements/Announcements';
import FinancialSituation from './containers/FinancialSituation/FinancialSituation';
import TransparentEarnings from './containers/TransparentEarnings/TransparentEarnings';
import ActivityReports from './containers/ActivityReports/ActivityReports';
import GoodsInventories from './containers/GoodsInventories/GoodsInventories';
import AdminGoodsInventories from './containers/Admin/GoodsInventories/GoodsInventories';
import AccessibilityWebsite from './components/AccessibilityWebsite/AccessibilityWebsite';
import Legislation from './containers/Legislation/Legislation';
import BalanceSheets from './containers/BalanceSheets/BalanceSheets';
import AdminBalanceSheets from './containers/Admin/BalanceSheets/BalanceSheets';
import AdminWorkAgenda from './containers/Admin/WorkAgenda/WorkAgenda';
import WorkAgenda from './containers/WorkAgenda/WorkAgenda';
import ProgramsStrategies from './containers/ProgramsStrategies/ProgramsStrategies';
import Programs from './containers/Admin/Programs/Programs';
import Strategies from './containers/Admin/Strategies/Strategies';
import PaymentSituation from './containers/PaymentSituation/PaymentSituation';
import InfoForm from './containers/InfoForm/InfoForm';
import NewsLetter from './containers/NewsLetter/NewsLetter.jsx';
import AdminPublicInterestDocuments from './containers/Admin/PublicInterestDocuments/PublicInterestDocuments';
import PublicInterestDocuments from './containers/PublicInterestDocuments/PublicInterestDocuments';
import StructureRegulament from './containers/StructureRegulament/StructureRegulament';
import Institutions from './containers/Institutions/Institutions';
import LawReports from './containers/LawRaports/LawReports';
import AcquisitionsProgram from './containers/AcquisitionsProgram/AcquisitionsProgram';
import AcquisitionsCentralizator from './containers/AcquisitionsCentralizator/AcquisitionsCentralizator';
import MemorandumContracts from './containers/MemorandumContracts/MemorandumContracts';
import GeneralForms from './containers/GeneralForms/GeneralForms.jsx';
import UrbanismQuestions from './containers/UrbanismQuestions/UrbanismQuestions.jsx';
import AdminAcquisitionsCentralizator from './containers/Admin/AdminAcquisitionsCentralizator/AdminAcquisitionsCentralizator';
import AdminAcquisitionsProgram from './containers/Admin/AdminAcquisitionsProgram/AdminAcquisitionsProgram';
import AdminActivityReports from './containers/Admin/AdminActivityReports/AdminActivityReports';
import AdminLawReports from './containers/Admin/AdminLawReports/AdminLawReports';
import Solicitations from './containers/Admin/Solicitations/Solicitations';
import AnimalDamages from './containers/AnimalDamages/AnimalDamages';
import AdminTransparencyEarnings from './containers/Admin/AdminTransparencyEarnings/AdminTransparencyEarnings';
import AdminElections from './containers/Admin/Elections/Elections';
import Elections from './containers/Elections/Elections';
import OrganizationRegulament from './containers/Admin/OrganizationRegulament/OrganizationRegulament';
import RegisterDecisionsProjects from './containers/Admin/RegisterDecisionsProjects/RegisterDecisionsProjects';
import RegisterAdoptedDecisions from './containers/Admin/RegisterAdoptedDecisions/RegisterAdoptedDecisions';
import RegisterDispozitions from './containers/Admin/RegisterDispozitions/RegisterDispozitions';
import RegisterDispositionsProject from './containers/Admin/RegisterDispositionsProjects/RegisterDispositionsProjects';
import DeniedAdministrativeActs from './containers/Admin/DeniedAdministrativeActs/DeniedAdministrativeActs';
import AdministrativeActsInfo from './containers/Admin/AdministrativeActsInfo/AdministrativeActsInfo';
import PublicDebatesInfo from './containers/Admin/PublicDebatesInfo/PublicDebatesInfo';
import ProposalsRegister from './containers/Admin/ProposalsRegister/ProposalsRegister';
import UserAdministrativeActsInfo from './containers/AdministrativeActsInfo/UserAdministrativeActsInfo';
import UserDeniedAdministrativeActs from './containers/UserDeniedAdministrativeActs//UserDeniedAdministrativeActs';
import UserProposalsRegister from './containers/UserProposalsRegister/UserProposalsRegister';
import UserPublicDebatesInfo from './containers/UserPublicDebatesInfo/UserPublicDebatesInfo';
import UserRegisterAdoptedDecisions from './containers/UserRegisterAdoptedDecisions/UserRegisterAdoptedDecisions';
import UserRegisterDecisionsProjects from './containers/UserRegisterDecisionsProjects/UserRegisterDecisionsProjects';
import UserRegisterDispositionsProjects from './containers/UserRegisterDispositionsProjects/UserRegisterDispositionsProjects';
import UserRegisterDispozitions from './containers/UserRegisterDispozitions/UserRegisterDispozitions';
import MonitorOficial from './containers/MonitorOficial/MonitorOficial';
import HandicapAid from './containers/HandicapAid/HandicapAid';
import ReportsLaw544 from './containers/Admin/ReportsLaw544/ReportsLaw544';
import AdminResponsesLaw544 from './containers/Admin/AdminResponsesLaw544/AdminResponsesLaw544';
import AdminNonrefundableFunds from './containers/Admin/AdminNonrefundableFunds/AdminNonrefundableFunds.jsx';
import AdminReportsLaw52 from './containers/Admin/AdminReportsLaw52/AdminReportsLaw52';
import AdminDebates from './containers/Admin/AdminDebates/AdminDebates';
import LawReportsResponses from './containers/LawReportsResponses/LawReportsResponses';
import NonrefundableFondsSituation from './containers/NonrefundableFondsSituation/NonrefundableFondsSituation';
import PublicDebates from './containers/PublicDebates/PublicDebates';
import ProposalsForm from './containers/ProposalsForm/ProposalsForm';
import LawReports52 from './containers/LawReports52/LawReports52';
import AssociationsRegister from './containers/AssociationsRegister/AssociationsRegister';
import Minutes from './containers/Admin/Minutes/Minutes';
import AdminAssociationRegister from './containers/Admin/AdminAssociationRegister/AdminAssociationRegister';
import UserMinutes from './containers/UserMinutes/UserMinutes';
import TaxesPage from './containers/TaxesPage/TaxesPage';
import AdminCouncilMeetings from './containers/Admin/CouncilMeetings/CouncilMeetings';
import CouncilMeetings from './containers/CouncilMeetings/CouncilMeetings';

const sucevitaTheme = createMuiTheme({
  pallete: {
    primary: '#4267B2',
    error: 'red'
  },
  shape: {
    borderRadius: '5px'
  },
  typography: {
    useNextVariants: true
  }
});

const Authentication = new AuthenticationService();
const FourOhFour = () => <h1>404</h1>;

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'sucevita'
});
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

class App extends Component {
  render() {
    return (
      <JssProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={sucevitaTheme}>
          <Router component={ScrollToTop}>
            <div className="app">
              <Switch>
                <DefaultRoute exact path="/" component={Homepage} />
                <DefaultRoute strict path="/contact" component={Contact} />
                <DefaultRoute
                  strict
                  path="/consilieri-locali"
                  component={LocalConcilors}
                />
                <DefaultRoute
                  strict
                  path="/publicatii-casatorie"
                  component={MariagePublications}
                />
                <DefaultRoute
                  strict
                  path="/inventar-bunuri"
                  component={GoodsInventories}
                />
                <DefaultRoute
                  strict
                  path="/presentare-generala"
                  component={GeneralPresentation}
                />
                <DefaultRoute strict path="/conducere" component={Lead} />
                <DefaultRoute
                  strict
                  path="/transparenta-venituri-salariale"
                  component={TransparentEarnings}
                />
                {/* <DefaultRoute
                  strict
                  path="/pug/certificate-urbanism"
                  component={UrbanismCertificate}
                />
                <DefaultRoute
                  strict
                  path="/pug/autorizatii-constructie"
                  component={ConstructionPermits}
                />
                <DefaultRoute strict path="/pug" component={PUG} /> */}
                <DefaultRoute
                  strict
                  path="/asistenta-sociala/ajutoare-incalzire"
                  component={HeatingAids}
                />
                <DefaultRoute
                  strict
                  path="/asistenta-sociala/stimulente-educationale-insertie"
                  component={EducationalStimulents}
                />
                <DefaultRoute
                  strict
                  path="/raspunsuri-lege-544-2001"
                  component={LawReportsResponses}
                />
                {
                  // <DefaultRoute
                  //   strict
                  //   path="/taxe-impozite"
                  //   component={TaxesPage}
                  // />
                }
                <DefaultRoute
                  strict
                  path="/asistenta-sociala/ajutor-social"
                  component={SocialAid}
                />
                <DefaultRoute
                  strict
                  path="/asistenta-sociala/alocatii"
                  component={Allocations}
                />
                <DefaultRoute
                  strict
                  path="/asistenta-sociala/indemnizatii-copil"
                  component={ChildAllowances}
                />
                <DefaultRoute
                  strict
                  path="/registrul-agricol"
                  component={AgriculturalRegister}
                />
                <DefaultRoute
                  strict
                  path="/inregistrare-nastere"
                  component={BirthRegistration}
                />
                <DefaultRoute
                  strict
                  path="/inregistrare-deces"
                  component={DeathRecord}
                />
                <DefaultRoute
                  strict
                  path="/inregistrare-casatorie"
                  component={MariageRecord}
                />
                <DefaultRoute
                  strict
                  path="/certificate-stare-civila"
                  component={CivilStatus}
                />
                <DefaultRoute
                  strict
                  path="/certificate-stare-civila-strainatate"
                  component={ForeignCivilStatus}
                />
                <DefaultRoute
                  strict
                  path="/evenimente/:id?"
                  component={EventPage}
                />
                <DefaultRoute
                  strict
                  path="/anunturi"
                  component={Announcements}
                />
                <DefaultRoute
                  strict
                  path="/resurse-umane"
                  component={HumanResources}
                />
                <DefaultRoute strict path="/jurist" component={Lawyer} />
                <DefaultRoute
                  strict
                  path="/achizitii"
                  component={Acquisitions}
                />
                <DefaultRoute
                  strict
                  path="/informatii-financiare"
                  component={FinancialSituation}
                />
                <DefaultRoute
                  strict
                  path="/proiecte-de-hotarari"
                  component={DraftDecisions}
                />
                <DefaultRoute
                  strict
                  path="/dispozitiile-primarului"
                  component={MajorProvision}
                />
                <DefaultRoute
                  strict
                  path="/hotarari-adoptate"
                  component={AdoptedDecisions}
                />
                <DefaultRoute
                  strict
                  path="/procese-verbale-sedinte"
                  component={VerbalSessions}
                />
                <DefaultRoute
                  strict
                  path="/convocare-ordine"
                  component={ConvocationAgenda}
                />
                <DefaultRoute
                  strict
                  path="/rapoarte-aplicare-lege-52-2003"
                  component={LawReports52}
                />
                <DefaultRoute
                  strict
                  path="/registru-asociatii"
                  component={AssociationsRegister}
                />
                <PrivateRoute exact path="/admin" component={Admin} />
                <PrivateRoute
                  strict
                  path="/admin/jurist"
                  component={AdminLawyer}
                />
                <PrivateRoute
                  strict
                  path="/admin/achizitii"
                  component={AdminAcquisitions}
                />
                <PrivateRoute
                  strict
                  path="/admin/dispozitiile-primarului"
                  component={AdminMajorProvisions}
                />
                <PrivateRoute
                  strict
                  path="/admin/proiecte-de-hotarari"
                  component={AdminDraftDecisions}
                />
                <PrivateRoute
                  strict
                  path="/admin/hotarari-adoptate"
                  component={AdminAdoptedDecisions}
                />
                <PrivateRoute
                  strict
                  path="/admin/convocare-ordine"
                  component={AdminConvocationAgenda}
                />
                <PrivateRoute
                  strict
                  path="/admin/procese-verbale-sedinte"
                  component={AdminVerbalSessions}
                />
                <PrivateRoute
                  strict
                  path="/admin/buget-local"
                  component={AdminLocalBudget}
                />
                <PrivateRoute
                  strict
                  path="/admin/informatii-financiare"
                  component={AdminFinancialSituation}
                />
                {/* <PrivateRoute
                  exact
                  path="/admin/pug/certificate-urbanism"
                  component={AdminPUGCertificate}
                />
                <PrivateRoute
                  exact
                  path="/admin/pug/autorizatii-constructie"
                  component={AdminPUGConstruction}
                /> */}
                <PrivateRoute
                  exact
                  path="/admin/anunturi"
                  component={AdminAnouncements}
                />
                <PrivateRoute
                  exact
                  path="/admin/inventar-bunuri"
                  component={AdminGoodsInventories}
                />
                <PrivateRoute
                  exact
                  path="/admin/resurse-umane"
                  component={AdminHumanResources}
                />
                <PrivateRoute
                  exact
                  path="/admin/evenimente"
                  component={AdminEvent}
                />
                <PrivateRoute
                  exact
                  path="/admin/asistenta-sociala/ajutor-social"
                  component={AdminSocialAid}
                />
                <PrivateRoute
                  exact
                  path="/admin/publicatii-casatorie"
                  component={MariagePublicationsAdmin}
                />
                <PrivateRoute
                  exact
                  path="/admin/proiecte-desfasurare"
                  component={AdminOngoingProjects}
                />
                <PrivateRoute
                  exact
                  path="/admin/proiecte-finalizate"
                  component={AdminCompletedProjects}
                />
                <PrivateRoute
                  exact
                  path="/admin/proiecte-finantare-externa"
                  component={AdminExternalProjects}
                />
                <PrivateRoute
                  exact
                  path="/admin/proiecte-depuse"
                  component={AdminSubmittedProjects}
                />
                <PrivateRoute
                  exact
                  path="/admin/bilanturi-contabile"
                  component={AdminBalanceSheets}
                />
                <DefaultRoute
                  exact
                  path="/proiecte-depuse"
                  component={SubmittedProjects}
                />
                <DefaultRoute
                  exact
                  path="/proiecte-desfasurare"
                  component={OngoingProjects}
                />
                <DefaultRoute
                  exact
                  path="/proiecte-finantare-externa"
                  component={ExternalProjects}
                />
                <DefaultRoute
                  exact
                  path="/proiecte-finalizate"
                  component={CompletedProjects}
                />
                <DefaultRoute
                  strict
                  path="/legislatie"
                  component={Legislation}
                />
                <DefaultRoute
                  exact
                  path="/rapoarte-activitate"
                  component={ActivityReports}
                />
                <DefaultRoute
                  exact
                  path="/bilanturi-contabile"
                  component={BalanceSheets}
                />
                <PrivateRoute
                  exact
                  path="/admin/agenda-de-lucru"
                  component={AdminWorkAgenda}
                />
                <DefaultRoute
                  exact
                  path="/programe-si-strategii"
                  component={ProgramsStrategies}
                />
                <DefaultRoute
                  exact
                  path="/agenda-de-lucru"
                  component={WorkAgenda}
                />
                <PrivateRoute
                  exact
                  path="/admin/programe"
                  component={Programs}
                />
                <PrivateRoute
                  exact
                  path="/admin/strategii"
                  component={Strategies}
                />
                <DefaultRoute
                  exact
                  path="/situatia-platilor"
                  component={PaymentSituation}
                />
                <DefaultRoute
                  exact
                  path="/formular-solicitare-informatii"
                  component={InfoForm}
                />
                <DefaultRoute
                  exact
                  path="/formular-solicitare-informatii/:token"
                  component={InfoForm}
                />
                <DefaultRoute
                  exact
                  path="/buletin-informativ"
                  component={NewsLetter}
                />
                <PrivateRoute
                  exact
                  path="/admin/documente-interes-public"
                  component={AdminPublicInterestDocuments}
                />
                <DefaultRoute
                  exact
                  path="/documente-interes-public"
                  component={PublicInterestDocuments}
                />
                <DefaultRoute
                  exact
                  path="/regulament-organizare"
                  component={StructureRegulament}
                />
                <DefaultRoute
                  exact
                  path="/situatie-fonduri-nerambursabile"
                  component={NonrefundableFondsSituation}
                />
                <DefaultRoute
                  exact
                  path="/institutii"
                  component={Institutions}
                />
                <DefaultRoute
                  exact
                  path="/rapoarte-aplicare-lege"
                  component={LawReports}
                />
                <DefaultRoute
                  exact
                  path="/program-achizitii"
                  component={AcquisitionsProgram}
                />
                <DefaultRoute
                  exact
                  path="/centralizator-achizitii"
                  component={AcquisitionsCentralizator}
                />
                <DefaultRoute
                  exact
                  path="/contracte-memorandum"
                  component={MemorandumContracts}
                />
                <DefaultRoute
                  exact
                  path="/formulare-tip"
                  component={GeneralForms}
                />
                <DefaultRoute
                  exact
                  path="/urbanism-intrebari-frecvente"
                  component={UrbanismQuestions}
                />
                <DefaultRoute
                  exact
                  path="/pagube-animale-salbatice"
                  component={AnimalDamages}
                />
                <PrivateRoute
                  exact
                  path="/admin/centralizator-achizitii"
                  component={AdminAcquisitionsCentralizator}
                />
                <PrivateRoute
                  exact
                  path="/admin/program-achizitii"
                  component={AdminAcquisitionsProgram}
                />
                <PrivateRoute
                  exact
                  path="/admin/rapoarte-activitate"
                  component={AdminActivityReports}
                />
                <PrivateRoute
                  exact
                  path="/admin/rapoarte-aplicare-lege"
                  component={AdminLawReports}
                />
                <PrivateRoute
                  exact
                  path="/admin/solicitari"
                  component={Solicitations}
                />
                <PrivateRoute
                  exact
                  path="/admin/transparenta-venituri-salariale"
                  component={AdminTransparencyEarnings}
                />
                <PrivateRoute
                  strict
                  path="/admin/alegeri-2024"
                  component={AdminElections}
                />
                <DefaultRoute
                  exact
                  path="/alegeri-2024"
                  component={Elections}
                />
                <DefaultRoute
                  exact
                  path="/informare-persoane-dizabilitati"
                  component={HandicapAid}
                />
                <DefaultRoute
                  exact
                  path="/dezbateri-publice"
                  component={PublicDebates}
                />
                <DefaultRoute
                  exact
                  path="/formular-propuneri"
                  component={ProposalsForm}
                />
                <PrivateRoute
                  strict
                  path="/admin/regulament-organizare"
                  component={OrganizationRegulament}
                />
                <PrivateRoute
                  strict
                  path="/admin/registru-evidenta-proiecte-hotarari"
                  component={RegisterDecisionsProjects}
                />
                <PrivateRoute
                  strict
                  path="/admin/registru-evidenta-hotarari-adoptate"
                  component={RegisterAdoptedDecisions}
                />
                <PrivateRoute
                  strict
                  path="/admin/registru-dispozitii"
                  component={RegisterDispozitions}
                />
                <PrivateRoute
                  strict
                  path="/admin/registru-proiecte-dispozitii"
                  component={RegisterDispositionsProject}
                />
                <PrivateRoute
                  strict
                  path="/admin/registru-inregistrare-refuzuri-acte-administrative"
                  component={DeniedAdministrativeActs}
                />
                <PrivateRoute
                  strict
                  path="/admin/informare-proiecte-acte-administrative"
                  component={AdministrativeActsInfo}
                />
                <PrivateRoute
                  strict
                  path="/admin/informare-probleme-interes-public"
                  component={PublicDebatesInfo}
                />
                <PrivateRoute
                  strict
                  path="/admin/consemnare-propuneri-hotarari-si-dispozitii"
                  component={ProposalsRegister}
                />
                <DefaultRoute
                  exact
                  path="/informare-proiecte-acte-administrative"
                  component={UserAdministrativeActsInfo}
                />
                <DefaultRoute
                  exact
                  path="/registru-inregistrare-refuzuri-acte-administrative"
                  component={UserDeniedAdministrativeActs}
                />
                <DefaultRoute
                  exact
                  path="/consemnare-propuneri-hotarari-si-dispozitii"
                  component={UserProposalsRegister}
                />
                <DefaultRoute
                  exact
                  path="/informare-probleme-interes-public"
                  component={UserPublicDebatesInfo}
                />
                <DefaultRoute
                  exact
                  path="/registru-evidenta-hotarari-adoptate"
                  component={UserRegisterAdoptedDecisions}
                />
                <DefaultRoute
                  exact
                  path="/registru-evidenta-proiecte-hotarari"
                  component={UserRegisterDecisionsProjects}
                />
                <DefaultRoute
                  exact
                  path="/registru-proiecte-dispozitii"
                  component={UserRegisterDispositionsProjects}
                />
                <DefaultRoute
                  exact
                  path="/registru-dispozitii"
                  component={UserRegisterDispozitions}
                />
                <DefaultRoute
                  exact
                  path="/monitorul-oficial-local"
                  component={MonitorOficial}
                />
                path="/admin/rapoarte-aplicare-lege-544-2001" component=
                {ReportsLaw544}
                />
                <PrivateRoute
                  strict
                  path="/admin/program-anual-achizitii"
                  component={AcquisitionsProgram}
                />
                <PrivateRoute
                  exact
                  path="/admin/raspunsuri-lege-544-2001"
                  component={AdminResponsesLaw544}
                />
                <PrivateRoute
                  exact
                  path="/admin/situatie-fonduri-nerambursabile"
                  component={AdminNonrefundableFunds}
                />
                <PrivateRoute
                  exact
                  path="/admin/rapoarte-aplicare-lege-52-2003"
                  component={AdminReportsLaw52}
                />
                <PrivateRoute
                  exact
                  path="/admin/dezbateri-publice"
                  component={AdminDebates}
                />
                <PrivateRoute
                  exact
                  path="/admin/registru-asociatii"
                  component={AdminAssociationRegister}
                />
                <PrivateRoute exact path="/admin/minute" component={Minutes} />
                <DefaultRoute exact path="/minute" component={UserMinutes} />
                <DefaultRoute strict path="/admin/login" component={Login} />
                <PrivateRoute
                  exact
                  path="/admin/sedinte-consiliu"
                  component={AdminCouncilMeetings}
                />
                <DefaultRoute
                  exact
                  path="/sedinte-consiliu"
                  component={CouncilMeetings}
                />
                <DefaultRoute component={FourOhFour} />
              </Switch>
            </div>
          </Router>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <div>
        <Header />
        <AccessibilityWebsite />
        <Component {...matchProps} />
        <Footer />
      </div>
    )}
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Authentication.loggedIn() ? (
        <div>
          <div className="admin-page">
            <div className="admin-page__left-content">
              <MenuAdmin />
            </div>
            <div className="admin-page__right-content">
              <Component {...props} />
            </div>
          </div>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: '/admin/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
