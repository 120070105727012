import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { acquisitionsService } from '../../services/AcquisitionsService';
import ReactPaginate from 'react-paginate';
import './Acquisitions.css';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class Acquisitions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acquisitions: [],
      searchAcquisitions: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    acquisitionsService
      .getAll()
      .then(jsonResponse => {
        this.setAcquisitionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setAcquisitionState = response => {
    let acquisitions = { ...this.state.acquisitions };
    let pagination = { ...this.state.pagination };
    acquisitions = response.acquisitions;
    pagination = response.pagination;

    this.setState({ acquisitions });
    this.setState({ pagination });
  };

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  updateField = event => {
    const field = event.target.name;
    const searchAcquisitions = this.state.searchAcquisitions;
    searchAcquisitions[field] = event.target.value;

    this.setState(
      {
        searchAcquisitions
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    acquisitionsService
      .search(this.state.searchAcquisitions, pageNumber)
      .then(jsonResponse => {
        this.setAcquisitionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Achizitii Primaria Sucevita</title>
          <meta
            name="description"
            content="Pagina de achizitii a primariei Sucevita. Aici sunt prezentate achizitiile realizate de primarie"
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Achizitii Primaria Sucevita" />
          <meta
            property="og:url"
            content="https://www.primaria-vicovudesus.ro/achizitii"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Achiziții </h4>
          </div>
          <h2 className="standard__main-title">Achiziții</h2>
        </div>
        <div className="standard__wrapper">
          <div className="mariage-publications__page__search">
            <div
              className="mariage-publications__search-area"
              id="aquisitions-search-area"
            >
              <div id="aquisitions-search-wrapper">
                <div
                  className="standard__input-box mariage-publications__input-box"
                  id="aquisition-input-box"
                >
                  <input
                    required
                    className="standard__input mariage-publications__input"
                    id="aquisition-input"
                    label="Cautare"
                    placeholder="Căutare dupa nume achizitie"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.searchAcquisitions.title}
                  />
                </div>
                <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                  <FormControl>
                    <NativeSelect
                      value={this.state.searchAcquisitions.archiveYear}
                      onChange={this.updateField}
                      inputProps={{
                        name: 'archiveYear'
                      }}
                    >
                      {this.state.archiveYears.map(archive => (
                        <option value={archive.value}>{archive.name}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>

              <div
                className="standard__input-box mariage-publications__input-box aquisition__date-wrapper"
                id="aquisition-input-box"
              >
                <TextField
                  className="aquisition__date-input"
                  label="Incepand de la data de"
                  name="start_date"
                  onChange={this.updateField}
                  value={this.state.searchAcquisitions.start_date}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <div
                className="standard__input-box mariage-publications__input-box aquisition__date-wrapper"
                id="aquisition-input-box"
              >
                <TextField
                  className="aquisition__date-input"
                  label="Pana la data de"
                  name="end_date"
                  onChange={this.updateField}
                  value={this.state.searchAcquisitions.end_date}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <button
                className="standard__button mariage-publications__button"
                id="aquisition-input"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => this.search()}
              >
                <h5 className="standard__button-writing mariage-publications__button-writing">
                  {' '}
                  CĂUTARE
                </h5>
              </button>
            </div>
          </div>

          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.acquisitions.length > 0 ? (
              this.state.acquisitions.map(acquisition => (
                <div className="standard__individual-card" key={acquisition.id}>
                  <h3 className="standard__card-title">{acquisition.title}</h3>
                  <h4 className="standard__card-date">
                    {acquisition.data_achizitie}
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${acquisition.document_invitation.url}`
                      )
                    }
                  >
                    Invitația de participare
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${acquisition.document_task_book.url}`
                      )
                    }
                  >
                    Caiet de sarcini
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${acquisition.document_forms.url}`
                      )
                    }
                  >
                    Formular
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${acquisition.document_contracts.url}`
                      )
                    }
                  >
                    Model Contract
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații în curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Acquisitions;
