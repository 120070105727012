import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { submittedProjectsService } from '../../services/SubmittedProjectsService';

class SubmittedProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existingProjects: false,
      existingHoldingProjects: [],
      existingApprovedProjects: [],
      existingRejectedProjects: []
    };
  }

  componentWillMount() {
    submittedProjectsService
      .getAll()
      .then(jsonResponse => {
        this.filterProjects(jsonResponse.submitted_projects);
      })
      .catch(error => console.log(error));
  }

  filterProjects = projects => {
    if (projects.length) {
      this.setState({ existingProjects: true });
    } else {
      this.setState({ existingProjects: false });
      return;
    }

    projects.forEach(project => {
      switch (project.stage) {
        case 'asteptare':
          let existingHoldingProjects = [...this.state.existingHoldingProjects];
          existingHoldingProjects.push(project);
          this.setState({ existingHoldingProjects });

          break;
        case 'aprobat':
          let existingApprovedProjects = [
            ...this.state.existingApprovedProjects
          ];
          existingApprovedProjects.push(project);
          this.setState({ existingApprovedProjects });

          break;
        case 'respins':
          let existingRejectedProjects = [
            ...this.state.existingRejectedProjects
          ];
          existingRejectedProjects.push(project);
          this.setState({ existingRejectedProjects });

          break;
        default:
      }
    });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Proiecte Depuse</title>
          <meta
            name="description"
            content="Proiecte Depuse Sucevita. Aici sunt prezentate proiectele depuse in Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Proiecte Depuse" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Proiecte > </h4>
            <h4 className="standard__generic">Proiecte depuse</h4>
          </div>
          <h2 className="standard__main-title">Proiecte depuse</h2>
        </div>

        <div className="standard__projects">
          {this.state.existingProjects ? (
            <div>
              <h3 className="standard__submitted-title">Proiecte aprobate</h3>
              <div
                className={
                  'standard__projects__wrapper ' +
                  (this.state.existingApprovedProjects.length <= 2
                    ? 'standard__projects-wrapper-few'
                    : '')
                }
              >
                {this.state.existingApprovedProjects.length > 0 ? (
                  this.state.existingApprovedProjects.map(project => (
                    <div
                      className="standard__individual-project"
                      key={project.id}
                    >
                      <div className="standard__project-image-info ">
                        <img
                          src="/images/generalPresentation/project.jpeg"
                          className="standard__project-image"
                          alt="project"
                        />
                        <div className="standard__project-info ">
                          <div className="standard__project-value-box">
                            <h4 className="standard__project-value">
                              {project.value} LEI
                            </h4>
                          </div>
                        </div>
                      </div>
                      <h3 className="standard__project-title">
                        {project.title}
                      </h3>
                      <h4 className="standard__project-main-description">
                        {project.description}
                      </h4>
                      <h4 className="standard__see-project">
                        Vezi Detalii Proiect
                      </h4>
                      {project.document && project.document.url !== null && (
                        <div className="submitted-projects__project-button">
                          <button
                            className="standard__button standard__project-button"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_API_URL}${project.document.url}`
                              )
                            }
                            variant="contained"
                            size="small"
                            color="primary"
                          >
                            <h4 className="standard__button-writing">
                              {' '}
                              Vezi Document Proiect
                            </h4>
                          </button>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="standard__no-projects-box">
                    <p className="standard__no-projects">
                      Nu sunt proiecte adăugate momentan.
                    </p>
                  </div>
                )}
              </div>
              <h3 className="standard__submitted-title">
                Proiecte în așteptare
              </h3>
              {this.state.existingHoldingProjects.length > 0 ? (
                <div
                  className={
                    'standard__projects__wrapper ' +
                    (this.state.existingHoldingProjects.length <= 2
                      ? 'standard__projects-wrapper-few'
                      : '')
                  }
                >
                  {this.state.existingHoldingProjects.map(project => (
                    <div
                      className="standard__individual-project"
                      key={project.id}
                    >
                      <div className="standard__project-image-info">
                        <img
                          src="/images/generalPresentation/project.jpeg"
                          className="standard__project-image"
                          alt="project"
                        />
                        <div className="standard__project-info ">
                          <div className="standard__project-value-box">
                            <h4 className="standard__project-value">
                              {project.value} LEI
                            </h4>
                          </div>
                          <div className="standard__project-date-box">
                            <h5 className="standard__project-date">
                              {project.start_date}-{project.end_date}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <h3 className="standard__project-title">
                        {project.title}
                      </h3>
                      <h4 className="standard__project-main-description">
                        {project.description}
                      </h4>
                      <h4 className="standard__see-project">
                        Vezi Detalii Proiect
                      </h4>

                      {project.document.url !== null && (
                        <div className="submitted-projects__project-button">
                          <button
                            className="standard__button standard__project-button"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_API_URL}${project.document.url}`
                              )
                            }
                            variant="contained"
                            size="small"
                            color="primary"
                          >
                            <h4 className="standard__button-writing">
                              {' '}
                              Vezi Document Proiect
                            </h4>
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Nu sunt proiecte în așteptare momentan.
                  </p>
                </div>
              )}
              <h3 className="standard__submitted-title">Proiecte respinse</h3>
              {this.state.existingRejectedProjects.length > 0 ? (
                <div
                  className={
                    'standard__projects__wrapper ' +
                    (this.state.existingRejectedProjects.length <= 2
                      ? 'standard__projects-wrapper-few'
                      : '')
                  }
                >
                  {this.state.existingRejectedProjects.map(project => (
                    <div
                      className="standard__individual-project"
                      key={project.id}
                    >
                      <div className="standard__project-image-info">
                        <img
                          src="/images/generalPresentation/project.jpeg"
                          className="standard__project-image"
                          alt="project"
                        />
                        <div className="standard__project-info ">
                          <div className="standard__project-value-box">
                            <h4 className="standard__project-value">
                              {project.value} LEI
                            </h4>
                          </div>
                          <div className="standard__project-date-box">
                            <h5 className="standard__project-date">
                              {project.start_date}-{project.end_date}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <h3 className="standard__project-title">
                        {project.title}
                      </h3>
                      <h4 className="standard__project-main-description">
                        {project.description}
                      </h4>
                      <h4 className="standard__see-project">
                        Vezi Detalii Proiect
                      </h4>

                      <div className="submitted-projects__project-title">
                        <h3>{project.title}</h3>
                      </div>
                      <div className="submitted-projects__project-description">
                        <h4>{project.description}</h4>
                      </div>
                      <div className="submitted-projects__project-value">
                        <h5>{`Valoare Proiect: ${project.value} RON`}</h5>
                      </div>
                      {project.document.url !== null && (
                        <div className="submitted-projects__project-button">
                          <button
                            className="standard__button standard__project-button"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_API_URL}${project.document.url}`
                              )
                            }
                            variant="contained"
                            size="small"
                            color="primary"
                          >
                            <h4 className="standard__button-writing">
                              {' '}
                              Vezi Document Proiect
                            </h4>
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Nu sunt proiecte respinse momentan.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="standard__no-projects-box">
              <p className="standard__no-projects">
                Nu sunt proiecte adăugate momentan.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SubmittedProjects;
