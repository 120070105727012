import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './MonitorOficial.css';
import MetaTags from 'react-meta-tags';

class MonitorOficial extends Component {
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    return (
      <div className="standard-page monitor">
        <MetaTags>
          <title>Monitorul Oficial Local</title>
          <meta
            name="description"
            content="Monitorul oficial local al primariei. Aici sunt prezentate detaliile cu privire la Monitorul Oficial Local."
          />
          <meta
            name="keywords"
            content="primarie, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere"
          />
          <meta property="og:title" content="Certificate de Stare Civila" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Monitor Oficial </h4>
          </div>
          <h2 className="standard__main-title">Monitorul Oficial Local</h2>
        </div>

        <div className="standard__wrapper monitor">
          <ul className="monitor__list">
            <h3 className="monitor__subtitle">
              Din data de 01.06.2021, Monitorul Oficial Local al comunei
              Sucevita poate fi vizualizat{' '}
              <a
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                aici
              </a>
              .
            </h3>
          </ul>
        </div>
      </div>
    );
  }
}

export default MonitorOficial;
