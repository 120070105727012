import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import MetaTags from 'react-meta-tags';
import './Allocations.css';

class Allocations extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Alocatii</title>
          <meta
            name="description"
            content="Pagina de alocatii a primariei Sucevita. Aici sunt prezentate legile in vigoare si necesitatile legate de alocatii."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Alocatii" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Asistență socială </h4>
          </div>
          <h2 className="standard__main-title">Alocații</h2>
        </div>
        <div className="standard__wrapper allocations-wrapper">
          <div className="heating-aids">
            <div className="heating-aids__left-side">
              <h3 className="standard__submitted-title allocations-title">
                Actele necesare întocmirii dosarului alocației de stat
              </h3>
              <ol className="standard__document-list">
                <li className="standard__list-item">
                  Copie certificat nastere copil (fata/verso)
                </li>
                <li className="standard__list-item">
                  Copie certificat de casatorie
                </li>
                <li className="standard__list-item">
                  Copie carti de indentitate parinti
                </li>
                <li className="standard__list-item">Cererea tip</li>
                <li className="standard__list-item">
                  Declaratie pe proprie raspundere din partea celuilalt parinte
                  ca este de acord ca ambii parinti un in grija pe copil
                </li>
                <li className="standard__list-item">
                  Extras de cont pe numele solicitantului
                </li>
                <li className="standard__list-item">Dosar cu sina</li>
              </ol>
              <h3 className="standard__submitted-title allocations-title">
                Actele necesare întocmirii dosarului alocației de susținere
              </h3>
              <ol className="standard__document-list">
                <li className="standard__list-item">Copie livret de familie</li>
                <li className="standard__list-item">
                  Copie certificat de nastere pentru copii
                </li>
                <li className="standard__list-item">
                  Copie certificat de casatorie
                </li>
                <li className="standard__list-item">
                  Copie carti de indentitate parinti
                </li>
                <li className="standard__list-item">
                  Copie carte de indentitate copii (unde este cazul)
                </li>
                <li className="standard__list-item">Adeverinta de elev</li>
                <li className="standard__list-item">
                  Adeverinta de venit agricol (pentru ambii parinti)
                </li>
                <li className="standard__list-item">
                  Adeverinta de venit impozabil - Trezorerie (pentru ambii
                  parinti)
                </li>
                <li className="standard__list-item">Adeverinta salariat</li>
                <li className="standard__list-item">
                  Cupoane de: pensie, somaj, pensie de intretinere, pensie de
                  ursmas, alocatie de stat
                </li>
                <li className="standard__list-item">
                  Copie certificat cu gradul de invaliditate pentru copii care
                  nu urmeaza o forma de invatatamant (unde este cazul)
                </li>
                <li className="standard__list-item">
                  Copie Hotarare judecatoreasca definitiva si irevocabila (unde
                  este cazul)
                </li>
                <li className="standard__list-item">
                  Persoanele care nu pot dovedi veniturile cu adeverinta, vor
                  declara pe propria raspundere veniturile din munci ocazionale
                  - pe o coala A4
                </li>
                <li className="standard__list-item">
                  Copie dupa actul de inmatriculare a autoturismului (unde este
                  cazul)
                </li>
              </ol>
              <h3 className="standard__submitted-title sa-documents-title standard__civil-status-title">
                Formulare tip necesare
              </h3>
              <div className="standard__cards sa-cards">
                <DownloadCard
                  requiredWidth="sa-required-width"
                  title="Cerere solicitare alocatie sustinere familie"
                  url="/documents/cerereSolicitareAjutorSocial.pdf"
                />
              </div>
            </div>
            <div className="heating-aids__right-side allocations-right-side">
              <h3 className="standard__submitted-title allocations-title allocations-title-switch">
                Actele necesare intocmirii dosarului alocatie de stat pentru
                copiii nascuti in strainatate
              </h3>
              <ol className="standard__document-list allocations-document-right">
                <li className="standard__list-item">
                  Copie certificat nastere copil (fata/verso)
                </li>
                <li className="standard__list-item">
                  Copie certificat de casatorie
                </li>
                <li className="standard__list-item">
                  Copie carti de indentitate parinti
                </li>
                <li className="standard__list-item">
                  Copie dupa numerele de indentificare copil si parinti de la
                  statul unde s-a nascut copilul (codice fiscale, etc)
                </li>
                <li className="standard__list-item">Anexa OO1</li>
                <li className="standard__list-item">Cererea tip</li>
                <li className="standard__list-item">
                  Declaratie pe proprie raspundere din partea parintilor cu
                  mentiunea daca au beneficiat/nu au beneficiat de alocatie
                  pentru copil de la stat in strainatate
                </li>
                <li className="standard__list-item">Dosar cu sina</li>
              </ol>
              <h3 className="standard__submitted-title allocations-title allocations-title-switch">
                Formulare tip necesare
              </h3>
              <DownloadCard
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
                title="Cerere pentru acordarea alocatiei de stat pentru copii"
                url="/documents/Cerere-copii0-18ani.pdf"
              />
              <DownloadCard
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
                title="Declaratii Concubinaj pentru solicitarea alocatie de stat"
                url="/documents/DeclaratiiConcubinajAlocatie.pdf"
              />
              <DownloadCard
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
                title="Declaratii soti pentru solicitarea alocatiei de stat"
                url="/documents/DeclaratiiSotiAlocatie.pdf"
              />
              <DownloadCard
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
                title="Declaratie partener strainatate pentru solicitarea alocatiei de stat"
                url="/documents/declaratie-partener-strainatate.pdf"
              />
              <DownloadCard
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
                title="Formular pentru copiii nascuti in spatiul UE"
                url="/documents/Formular_copii_nascuti_in_spatiul_UE.pdf"
              />
              <DownloadCard
                titleColor="allocations-download-title"
                backgroundColor="allocations-download-card"
                flexClass="allocations-card-width"
                title="Cuantum alocatie sustinere familie"
                url="/documents/cuantum_alocatie_sustinere_familie.pdf"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Allocations;
