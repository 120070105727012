import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const acquisitionsCentralizatorService = {
  getAll,
  createOrUpdate,
  deleteCentralizator,
  search
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};
function search(searchCentralizator, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/public_aquisitions_centralizators/search?title=${
    searchCentralizator.title
  }&archiveYear=${searchCentralizator.archiveYear}&page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/public_aquisitions_centralizators/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newCentralizator) {
  const requestOptions = {
    method: newCentralizator.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newCentralizator)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/public_aquisitions_centralizators${
    newCentralizator.id ? `/${newCentralizator.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(centralizator) {
  let formData = new FormData();
  formData.append(
    'public_aquisitions_centralizator[add_date]',
    centralizator.date
  );
  formData.append(
    'public_aquisitions_centralizator[title]',
    centralizator.title
  );

  const document = centralizator.document;

  if (document) {
    if (document.url) {
      formData.append(
        `public_aquisitions_centralizator[document]`,
        document.url
      );
    } else {
      formData.append(
        `public_aquisitions_centralizator[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deleteCentralizator(centralizatorId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/public_aquisitions_centralizators/${centralizatorId}`;

  return axios.delete(url, requestOptionsDelete);
}
