import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const minuteService = {
  getAll,
  createOrUpdate,
  deleteMinute
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/local_public_meetings_minutes/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newMinute) {
  const requestOptions = {
    method: newMinute.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newMinute)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/local_public_meetings_minutes${newMinute.id ? `/${newMinute.id}` : ''}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(minute) {
  let formData = new FormData();
  formData.append('local_public_meetings_minute[add_date]', minute.date);
  formData.append('local_public_meetings_minute[title]', minute.title);

  const document = minute.document;

  if (document) {
    if (document.url) {
      formData.append(`local_public_meetings_minute[document]`, document.url);
    } else {
      formData.append(
        `local_public_meetings_minute[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deleteMinute(minuteId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/local_public_meetings_minutes/${minuteId}`;

  return axios.delete(url, requestOptionsDelete);
}
