// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class HealthSocialCare extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Infrastructura de sanatate si asistenta sociala
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Serviciile de sănatate
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Pentru asigurarea sănătăţii umane, în oraşul Sucevita
                funcţionează Unitatea Medico – Sociala Sucevita . Această clasă
                include unităţi care deservesc unitatea
                administrativ-teritorială și care se limitează la afecțiuni cu
                grad mic de complexitate.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Totodată, în localitate funcționează sase cabinete medicale
                familiale și cinci cabinete de specialitate – cabinete medicale
                stomatologice individuale.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">NR CRT.</div>
                  <div className="text text--final">
                    Furnizori de servicii de sănătate
                  </div>
                  <div className="text text--final">
                    Unitate de stat / Privată
                  </div>
                </div>
                <div className="table__row">
                  <div className="text text--final">1.</div>
                  <div className="text text--final">
                    Unitatea Medico Sociala
                  </div>
                  <div className="text text--final">Stat</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2.</div>
                  <div className="text text--final">Dispensare</div>
                  <div className="text text--final">Privat</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">3.</div>
                  <div className="text text--final">Cabinete Stomatologice</div>
                  <div className="text text--final">Privat</div>
                </div>
              </div>
              <h5
                className="standard__generic dd-description"
                id="gp-description"
              >
                Furnizori de servicii de sănătate
              </h5>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">Nr Crt.</div>
                  <div className="text text--final">Indicatori</div>
                  <div className="text text--final">2014</div>
                  <div className="text text--final">2013</div>
                  <div className="text text--final">2012</div>
                  <div className="text text--final">2011</div>
                  <div className="text text--final">2010</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">1</div>
                  <div className="text text--final">
                    Număr medici de familie
                  </div>
                  <div className="text text--final">6</div>
                  <div className="text text--final">6</div>
                  <div className="text text--final">5</div>
                  <div className="text text--final">5</div>
                  <div className="text text--final">4</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">1</div>
                  <div className="text text--final">
                    Număr medici specialisti
                  </div>
                  <div className="text text--final">5</div>
                  <div className="text text--final">5</div>
                  <div className="text text--final">4</div>
                  <div className="text text--final">3</div>
                  <div className="text text--final">3</div>
                </div>
              </div>
              <h5
                className="standard__generic dd-description"
                id="gp-description"
              >
                Indicatori servicii de sănătate
              </h5>
              <h4 className="standard__generic aa-info" id="gp-description">
                Unitatea Medico Sociala din Orasul Sucevita este o unitate care
                oferă pacienţilor o gamă limitata de servicii medicale, oferind
                servicii sociale unei populaţii de 20.000 de locuitori şi în
                care aproximativ 240 de bolnavi se internează anual aici.
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Serviciile de asistenţă socială
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Potrivit prevederilor Legii nr. 448/2006 republicată, rolul
                autorităţii locale este de a monitoriza în condiții optime
                atribuțiile și obligațiile care le revin asistenților personali
                în vederea ameliorării situației persoanelor cu handicap grav
                astfel încât să primească îngrijire specială la nivelul la care
                starea lor o cere.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Îngrijirile ce li se acordă pot permite persoanelor bolnave
                să-și valorifice potențialul fizic, intelectual, spiritual,
                emoțional și social, în pofida handicapului de care suferă.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                În prezent în cadrul Primăriei orașului Sucevita sunt angajaţi
                78 de asistenti personali ai persoanelor cu handicap din care 59
                persoane sunt asistenți personali pentru adulţi iar 19 asistenți
                personali pentru minori.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                La nivelul orașului Sucevita sunt înregistrate 21 persoane cu
                handicap gradul grav care au optat pentru primirea unei
                indemnizații lunare în locul angajarii unui asistent personal.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                De asemenea, pe baza O.U.G. nr. 27/27.08.2013, pentru
                modificarea şi completarea O.U.G. nr. 70/2011 şi a H.G. nr.
                778/09.10.2013, Primăria acordă ajutoare pentru încalzirea
                locuinţelor. Numărul cererilor anuale pentru acordarea
                ajutorului la încălzire a fost de 50 in anul 2014-2015.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text text--final">ANUL</div>
                  <div className="text text--final">BUGETUL (lei)</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2010</div>
                  <div className="text text--final">2.978.800</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2011</div>
                  <div className="text text--final">731.982</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2012</div>
                  <div className="text text--final">889.072</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2013</div>
                  <div className="text text--final">1.211.847</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">2014</div>
                  <div className="text text--final">1.462.200</div>
                </div>
              </div>
              <h5
                className="standard__generic dd-description"
                id="gp-description"
              >
                Bugetul oraşului Sucevita pentru asistenţă sociaal in perioada
                2010 – 2014
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HealthSocialCare;
