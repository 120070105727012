import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    return (
      <footer className="footer">
        <div className="footer__wrapper">
          <div className="footer__column">
            <ul className="footer__list">
              <li className="footer__item footer__title">
                <Link to="/conducere" className="footer__link">
                  PREZENTARE
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/legislatie" className="footer__link">
                  Legislație
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/conducere" className="footer__link">
                  Conducere
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/agenda-de-lucru" className="footer__link">
                  Agenda de lucru
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/regulament-organizare" className="footer__link">
                  Regulament de organizare
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/" className="footer__link">
                  Organigramă
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/resurse-umane" className="footer__link">
                  Carieră
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/dispozitiile-primarului" className="footer__link">
                  Dispozițiile primarului
                </Link>
              </li>
            </ul>

            <ul className="footer__list">
              <li className="footer__item footer__title">
                <Link to="/consilieri-locali" className="footer__link">
                  CONSILIUL LOCAL
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/consilieri-locali" className="footer__link">
                  Consilieri
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/proiecte-de-hotarari" className="footer__link">
                  Proiecte de hotărâri
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/convocare-ordine" className="footer__link">
                  Convocare și ordine de zi
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/hotarari-adoptate" className="footer__link">
                  Hotărâri adoptate
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/procese-verbale-sedinte" className="footer__link">
                  Procese verbale ședințe
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer__column">
            <ul className="footer__list">
              <li className="footer__item footer__title">INTERES PUBLIC</li>
              <li className="footer__item">
                <Link
                  to="/formular-solicitare-informatii"
                  className="footer__link"
                >
                  Formular pentru solicitare informații
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/buletin-informativ" className="footer__link">
                  Buletinul informativ
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/informatii-financiare" className="footer__link">
                  Buget pe surse financiare
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/bilanturi-contabile" className="footer__link">
                  Bilanțuri contabile
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/achizitii" className="footer__link">
                  Achiziții publice
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/formulare-tip" className="footer__link">
                  Formulare tip
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/proiecte-desfasurare" className="footer__link">
                  Proiecte în desfășurare
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/proiecte-finalizate" className="footer__link">
                  Proiecte finalizate
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/proiecte-finantare-externa" className="footer__link">
                  Proiecte cu finanțare externă
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/proiecte-depuse" className="footer__link">
                  Proiecte depuse
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer__column">
            <ul className="footer__list">
              <li className="footer__item footer__title">SERVICII</li>
              <li className="footer__item">
                <Link to="/asistenta-sociala" className="footer__link">
                  Asistență socială
                </Link>
              </li>
              <li className="footer__item">
                <Link
                  to="/asistenta-sociala/ajutoare-incalzire"
                  className="footer__link"
                >
                  Ajutoare încălzire
                </Link>
              </li>
              <li className="footer__item">
                <Link
                  to="/asistenta-sociala/stimulente-educationale-insertie"
                  className="footer__link"
                >
                  Stimulente educaționale și inserție
                </Link>
              </li>
              <li className="footer__item">
                <Link
                  to="/asistenta-sociala/ajutor-social"
                  className="footer__link"
                >
                  Ajutor social
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/asistenta-sociala/alocatii" className="footer__link">
                  Alocații
                </Link>
              </li>
              <li className="footer__item">
                <Link
                  to="/asistenta-sociala/indemnizatii-copil"
                  className="footer__link"
                >
                  Indemnizații creștere copil
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/publicatii-casatorie" className="footer__link">
                  Starea civilă
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/publicatii-casatorie" className="footer__link">
                  Publicații de căsătorie
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/inregistrare-nastere" className="footer__link">
                  Înregistrare naștere
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/inregistrare-deces" className="footer__link">
                  Înregistrare deces
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/inregistrare-casatorie" className="footer__link">
                  Înregistrare căsătorie
                </Link>
              </li>
              <li className="footer__item">
                <Link to="" className="footer__link">
                  Eliberare certificare de stare civilă
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/certificate-stare-civila" className="footer__link">
                  Certificate de stare civilă eliberare în străinătate
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/registrul-agricol" className="footer__link">
                  Registrul Agricol
                </Link>
              </li>

              <li className="footer__item">
                <Link
                  to="/urbanism-intrebari-frecvente"
                  className="footer__link"
                >
                  Urbanism - Intrebari Frecvente
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/jurist" className="footer__link">
                  Jurist
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer__column">
            <ul className="footer__list">
              <li className="footer__item footer__title">
                <Link to="/anunturi" className="footer__link">
                  NOUTĂȚI
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/anunturi" className="footer__link">
                  Anunțuri
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/evenimente" className="footer__link">
                  Evenimente
                </Link>
              </li>
            </ul>
            <ul className="footer__list">
              <Link to="/contact" className="footer__link">
                <li className="footer__item footer__title">CONTACT</li>
              </Link>
              <li className="footer__item">0230417102</li>
              <li className="footer__item">office@primariasucevita.ro</li>
              <li className="footer__item">
                Str. Calea Movileștilor, nr. 153, Sucevița
              </li>
              <li className="footer__item">Program: 08:00-16:00</li>
            </ul>
          </div>
        </div>
        <img
          className="footer__up"
          src="/images/icons/up-arrow.svg"
          alt="up-arrow"
          onClick={this.scrollToTop}
        />
        <a
          href="https://www.primaria-ta.ro"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__outer-link"
        >
          <h4 className="footer__copyright footer__copy"> Primaria Ta </h4>
        </a>
        <h4 className="footer__copyright">Designed and maintained by </h4>
        <a
          href="https://www.prometeus-tech.com"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__outer-link"
        >
          <h4 className="footer__copyright">© Prometeus Tech 2020</h4>
        </a>
      </footer>
    );
  }
}

export default Footer;
