import React, { Component } from 'react';
import bannerImages from './bannerImages.json';
import './Homepage.css';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import MetaTags from 'react-meta-tags';
import { eventService } from '../../services/EventService';
import { announcementsService } from '../../services/AnnouncementsService';
import { ongoingProjectsService } from '../../services/OngoingProjectsService';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import Moment from 'react-moment';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bannerImages: bannerImages,
      lastAnnouncement: {},
      lastEvent: {},
      lastProject: {}
    };
  }

  componentWillMount() {
    eventService
      .lastEvent()
      .then(jsonResponse => {
        if (jsonResponse.data.event.length) {
          this.setState({ lastEvent: jsonResponse.data.event[0] });
        }
      })
      .catch(error => console.log(error));

    announcementsService
      .getLast()
      .then(jsonResponse => {
        if (jsonResponse.data.announcement.length) {
          this.setState({
            lastAnnouncement: jsonResponse.data.announcement[0]
          });
        }
      })
      .catch(error => console.log(error));

    ongoingProjectsService
      .lastProject()
      .then(jsonResponse => {
        if (jsonResponse.data.project.length) {
          this.setState({ lastProject: jsonResponse.data.project[0] });
        }
      })
      .catch(error => console.log(error));
  }

  openUsefullLinks = url => {
    window.open(url, '_blank');
  };

  render() {
    return (
      <div className="homepage">
        <MetaTags>
          <title>Primaria Sucevita</title>
          <meta
            name="description"
            content="Bine ati venit pe pagina oficiala a primariei Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Primaria Sucevita" />
          <meta property="og:url" content="https://www.primariasucevita.ro/" />
        </MetaTags>
        <div className="homepage__banner">
          <ImageNextGen
            class="homepage__banner-img"
            imageUrl="/images/bannerImages/IMG_7241.jpg"
            imageUrlWebp="/images/bannerImages/IMG_7241.webp"
            imageUrlJpeg2000="/images/bannerImages/IMG_7241.jp2"
          />
          {Object.keys(this.state.lastAnnouncement).length ? (
            <div className="homepage__banner-info">
              <div className="homepage__banner-description">
                <h3 className="homepage__banner-title">
                  {this.state.lastAnnouncement.title}
                </h3>
                <h4 className="homepage__banner-subtitle">
                  {this.state.lastAnnouncement.description}
                </h4>
              </div>
              <div className="homepage__read-more-box">
                <Link className="homepage__middle-link" to="/anunturi">
                  <h5 className="homepage__read-more">Citeste mai multe</h5>
                </Link>
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>
        <div className="homepage__middle-section">
          <h3 className="homepage__monitor-title">Monitorul Oficial Local</h3>
          <div className="homepage__middle-section-second">
            <div className="homepage__card">
              <a
                className="homepage__middle-link"
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Statutul Unității Administrativ-Teritoriale
                </h4>
              </a>
            </div>

            <div className="homepage__card">
              <a
                className="homepage__middle-link"
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Regulamentele privind Procedurile Administrative
                </h4>
              </a>
            </div>

            <div className="homepage__card">
              <a
                className="homepage__middle-link"
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Hotărârile Autorității Deliberative
                </h4>
              </a>
            </div>

            <div className="homepage__card">
              <a
                className="homepage__middle-link"
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Dispozițiile Autorității Executive
                </h4>
              </a>
            </div>

            <div className="homepage__card">
              <a
                className="homepage__middle-link"
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Documente și Informații Financiare
                </h4>
              </a>
            </div>

            <div className="homepage__card">
              <a
                className="homepage__middle-link"
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Documente de interes public
                </h4>
              </a>
            </div>
          </div>
        </div>
        <div className="homepage__comunicat">
          <div className="homepage__comunicat-box">
            <h3>
              {' '}
              <b>
                Proiect ”Dezvoltarea sistemului de management local” Sucevița
                2023
              </b>
            </h3>
            <p>
              Comunicat de presă „PNRR: Fonduri pentru România modernă și
              reformată!”
            </p>
            <p> Numele beneficiarului: Comuna SUCEVIȚA</p>
            <p>
              Apel de proiecte gestionat de Ministerul Dezvoltării, Lucrărilor
              Publice și Administrației finanțat din fonduri europene prin
              Planul Național de Redresare și Reziliență al României și din
              fonduri naționale, Componenta C10: FONDUL LOCAL, Investiția I.1.2
              - Asigurarea infrastructurii pentru transportul verde – ITS/alte
              infrastructuri TIC (sisteme inteligente de management urban/local)
              și I.1.3 – Asigurarea infrastructurii pentru transportul verde –
              puncte de reîncărcare vehicule electrice, titlu apel:
              PNRR/2022/C10/I1.2, PNRR/2022/C10/I1.3, Runda 1 Numele proiectului
              de investiție: ”Dezvoltarea sistemului de management local prin
              implementarea unor infrastructuri inteligente la nivelul Comunei
              Sucevița, Județul Suceava” Cod proiect: C10-I1.2-1338 / 24.05.2022
              Contract de finanțare nr. 2612/10.01.2023 Durata proiectului: 27
              de luni; Data de începere: 10.01.2023; Data finalizare: 10.04.2025
              Investiția permite achiziționarea de sisteme ITS (Intelligent
              Transport Systems/sisteme de transport inteligente) și TIC
              (Tehnologia Informațiilor și Comunicațiilor) ce contribuie la
              îmbunătățirea mobilității locale, respectiv a managementului
              local. De asemenea, se permite și achiziționarea de stații de
              reîncărcare pentru vehicule electrice.
            </p>
            <p>Obiectivele generale urmarite:</p>
            <ul>
              <li>
                Creșterea gradului de satisfacție a cetățenilor fata de
                administrația publica locala
              </li>
              <li>
                {' '}
                Asigurarea unui climat civilizat, curat si sigur pentru
                cetățenii comunității
              </li>
              <li> Compensarea decalajelor intre mediul rural si cel urban</li>
              <li>
                {' '}
                Creșterea calității serviciilor publice si extinderea ariei de
                activitate a acestora
              </li>
            </ul>
            <p>Obiectivele specifice urmarite:</p>
            <ul>
              <li> Creșterea interacțiunii cu cetățenii comunității</li>
              <li>
                {' '}
                Încurajarea spiritului civic si a participării cetățenilor la
                actul de administrare
              </li>
              <li>
                {' '}
                Simplificarea procedurilor si scăderea timpilor de lucru si de
                așteptare in cadrul proceselor autorității publice locale
              </li>
              <li>
                {' '}
                Creșterea calității si gradului de utilizare a serviciilor
                publice comunitare
              </li>
              <li>Monitorizarea si optimizarea consumurilor de energie</li>
              <li>
                {' '}
                Crearea bazelor de date a consumurilor de energie si a
                parametrilor de mediu Instalarea de mobilier urban inteligent,
                conducând la creșterea ariei de acoperire a sistemului WiFi in
                spatiile publice si creșterea ariilor iluminate
              </li>
              <li>
                Încurajarea utilizării transportului in comun prin crearea de
                spatii inteligente de îmbrăcare / debarcare a calatorilor
              </li>
              <li>
                Monitorizarea si controlul in timp real a infrastructurii
                tehnico-municipale de iluminat public
              </li>
              <li>Monitorizarea parametrilor relevanți de mediu</li>
              <li>
                Dezvoltarea infrastructurii de reîncărcare a autovehiculelor
                electrice și hybrid
              </li>
            </ul>
            <p>
              <b>Valoare totală proiect:</b> 2.194.163,65 lei, din care valoarea
              nerambursabilă finantata din PNRR: : 1.843.835,00 lei.
            </p>
            <p>
              <b>
                {' '}
                Detalii suplimentare se pot obține de la persoana de contact:
                CAZAC VLAD BOGDAN - Resposabil achizitii, telefon: 0740510531,
                e-mail: office@primariasucevita.ro
              </b>
            </p>
            <p>
              {' '}
              „Conținutul acestui material nu reprezintă în mod obligatoriu
              poziția oficială a Uniunii Europene sau a Guvernului României”.
            </p>

            <p>
              Pentru informaţii detaliate, vă invităm să vizitaţi{' '}
              <a
                href="https://mfe.gov.ro/pnrr/"
                target="_blank"
                className="standard__link"
              >
                www.mfe.gov.ro/pnrr/
              </a>
              , pagina de Facebook
              <a
                href="https://www.facebook.com/PNRROficial/"
                target="_blank"
                className="standard__link"
              >
                https://www.facebook.com/PNRROficial/
              </a>
              , precum si{' '}
              <span
                className="standard__link"
                onClick={() => window.open(`/documents/comunicat_proiect.pdf`)}
              >
                <b>Comunicatul de presa</b>
              </span>
              .
            </p>
          </div>
        </div>
        {
          // <div className="homepage__taxes-section">
          //   <h3 className="homepage__taxes">
          //     Pentru plata impozitelor, taxelor locale și amenzilor, Primăria vă
          //     pune la dispoziție
          //     <a
          //       className="homepage__link standard__taxes-link"
          //       href="https://infopay.ro/Institutie/vicovs"
          //       target="_blank"
          //       rel="noopener noreferrer"
          //     >
          //
          //       portalul online InfoPay
          //     </a>
          //     . Acest sistem de plată electronică este disponibil atât pentru
          //     persoanele fizice, cât și pentru persoanele juridice.
          //   </h3>
          //   <h3 className="homepage__taxes">
          //
          //     Pentru plata impozitelor și taxelor locale prin intermediul InfoPay
          //     nu se percep comisioane pentru tranzacțiile efectuate.
          //   </h3>
          // </div>
        }
        <div className="homepage__events-and-projects">
          <div className="homepage__general-news">
            <h2 className="homepage__general-title">Evenimente</h2>
            {Object.keys(this.state.lastEvent).length ? (
              <div>
                <h3 className="homepage__general-subtitle">
                  Cel mai recent eveniment din localitatea noastră
                </h3>
                <div className="homepage__general-pic-box">
                  <img
                    src={
                      this.state.lastEvent.picture &&
                      this.state.lastEvent.picture.url
                        ? `${process.env.REACT_APP_API_URL}/${this.state.lastEvent.picture.url}`
                        : ''
                    }
                    className="homepage__general-news-pic"
                    alt="event-pic"
                  />
                  <div className="homepage__news-date-box">
                    <h4 className="homepage__news-date">
                      <Moment
                        format="DD-MM-YYYY"
                        date={this.state.lastEvent.start_date}
                      />
                    </h4>
                  </div>
                </div>
                <h3 className="homepage__general-name">
                  {this.state.lastEvent.title}
                </h3>
                <h4 className="homepage__general-description">
                  {this.state.lastEvent.description}
                </h4>
                <button className="homepage__general-button">
                  <h5 className="homepage__general-see-more">Vezi eveniment</h5>
                </button>
              </div>
            ) : (
              undefined
            )}
          </div>
          <div className="homepage__general-news homepage__project">
            <h2 className="homepage__general-title homepage__project-main-title">
              Proiecte
            </h2>
            <h3 className="homepage__general-subtitle homepage__project-subtitle">
              Cel mai recent proiect din localitatea noastră
            </h3>
            {Object.keys(this.state.lastProject).length ? (
              <div>
                <div className="homepage__general-pic-box">
                  <img
                    src={
                      this.state.lastProject.picture &&
                      this.state.lastProject.picture.url
                        ? `${process.env.REACT_APP_API_URL}/${this.state.lastProject.picture.url}`
                        : ''
                    }
                    className="homepage__general-news-pic"
                    alt="project-pic"
                  />
                  <div className="homepage__news-date-box homepage__project-date-box">
                    <h4 className="homepage__news-date homepage__project-date">
                      <Moment
                        format="DD-MM-YYYY"
                        date={this.state.lastProject.start_date}
                      />
                    </h4>
                  </div>
                </div>
                <h3 className="homepage__general-name homepage__project-name">
                  {this.state.lastProject.title}
                </h3>
                <h4 className="homepage__general-description homepage__project-description">
                  {this.state.lastProject.description}
                </h4>
                <button className="homepage__general-button">
                  <h5 className="homepage__general-see-more">Vezi proiect</h5>
                </button>
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>
        <div className="homepage__usefull-links">
          <h2>Linkuri Externe</h2>
          <div className="homepage__usefull-links-list">
            <div className="homepage__card homepage__card-link">
              <Link
                to="https://sgg.gov.ro/new/guvernare-transparenta-deschisa-si-participativa-standardizare-armonizare-dialog-imbunatatit-cod-sipoca-35/"
                target="_blank"
              >
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Guvernare transparentă, deschisă și participativă
                </h3>
              </Link>
            </div>
            <div className="homepage__card homepage__card-link">
              <Link to="/documents/CoronaVirusPrevenire.pdf">
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Masuri de protectie impotriva CoronaVirus
                </h3>
              </Link>
            </div>
          </div>
        </div>
        <CookieConsent
          location="bottom"
          flipButtons
          buttonText="Accept Cookies"
          declineButtonText="Refuz Cookies"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          Acest site folosește cookies. Navigând în continuare vă exprimați
          acordul asupra folosirii cookie-urilor.{' '}
        </CookieConsent>
      </div>
    );
  }
}

export default Homepage;
