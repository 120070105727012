import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const reportResponseService = {
  getAll,
  createOrUpdate,
  deleteResponse
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/aplicability_report_answers/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newResponse) {
  const requestOptions = {
    method: newResponse.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newResponse)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/aplicability_report_answers${
    newResponse.id ? `/${newResponse.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(reponse) {
  let formData = new FormData();

  formData.append('aplicability_report_answer[title]', reponse.title);
  formData.append('aplicability_report_answer[add_date]', reponse.date);

  const document = reponse.document;

  if (document) {
    if (document.url) {
      formData.append(`aplicability_report_answer[document]`, document.url);
    } else {
      formData.append(
        `aplicability_report_answer[document]`,
        document,
        document.name
      );
    }
  }

  return formData;
}
function deleteResponse(responseId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/aplicability_report_answers/${responseId}`;

  return axios.delete(url, requestOptionsDelete);
}
